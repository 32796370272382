import { useParams } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import Layout from "../common/app.layout";
import { useMutation } from "@apollo/client";
import { ADD_BULK_COLLABORATORS } from "../queries/channel";
import { useRef } from "react";

export default function CollabInviteForm({ onSubmit }) {
  const { workspaceId } = useParams();
  const qs = useQueryParams();

  const [addBulkCollabs] = useMutation(ADD_BULK_COLLABORATORS);
  const collabEmails = useRef(qs.get("collabs").split(","));
  const collabRole = useRef(qs.get("role").trim());

  async function inviteCollaborators() {
    await addBulkCollabs({
      fetchPolicy: "no-cache",
      variables: {
        documentId: workspaceId,
        collabEmails: collabEmails.current,
        role: collabRole.current,
      },
    });
  }

  return (
    <Layout bgClass="bg-white">
      <div>
        <button
          className="text-sm flex items-center space-x-3 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
          onClick={inviteCollaborators}
        >
          Invite
        </button>
      </div>
    </Layout>
  );
}
