import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { REGIONS } from "../helpers/utils";

function RegionPicker({ onChange }) {
  const [selectedRegion, setSelectedRegion] = useState(REGIONS[0]);

  return (
    <Listbox
      value={selectedRegion}
      onChange={(code) => {
        setSelectedRegion(REGIONS.find((r) => r.code === code));
        onChange(code);
      }}
    >
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">{selectedRegion.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-900"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full px-2 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {REGIONS.map((region) => (
              <Listbox.Option
                className={({ active }) =>
                  `relative cursor-default select-none pl-3 py-2 rounded-md ${
                    active
                      ? "bg-amber-100 text-amber-900 hover:bg-creately-primary-xlight"
                      : "text-creately-primary-xdark"
                  }`
                }
                key={region.id}
                value={region.code}
              >
                {region.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default RegionPicker;
