import React from "react";
import { getInitials } from "../helpers/utils";

export default function UserAvatar(props) {
  const { name, image } = props.user;

  return (
    <div className="rounded-full overflow-hidden w-10 h-10 flex items-center justify-center bg-creately-primary-dark">
      {image && (
        <img
          className="block w-10 h-10"
          src={image}
          alt={`Avatar of ${name}`}
        />
      )}
      {!image && (
        <span className="inline-block text-creately-theme-beige font-semibold">
          {getInitials(name)}
        </span>
      )}
    </div>
  );
}
