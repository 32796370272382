import { Redirect } from "react-router-dom";
import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";
import CreatelyEditorFrame from "../components/CreatelyEditorFrame";
import { useAuth } from "../contexts/auth.context";
import { useState } from "react";
import LoginPrompt from "../components/LoginPrompt";

function PersonalIndexPage() {
  const [{ teamsContext }] = useTeams();
  const { logout } = useAuth();
  const [showLoginPrompt, setShowLoginPrompt] = useState();
  const [readyToShow, setReadyToShow] = useState(false);

  if (teamsContext && teamsContext?.page?.subPageId) {
    return (
      <Redirect
        to={{
          pathname: `/app/${teamsContext.page.subPageId}`,
          state: { dashboardPath: "/personal" },
        }}
      />
    );
  }

  return (
    <Layout>
      {showLoginPrompt && (
        <div className="fixed top-0 left-0 w-full h-full">
          <LoginPrompt
            onSuccess={(userInfo) => {
              setShowLoginPrompt(false);
            }}
          />
        </div>
      )}
      <CreatelyEditorFrame
        workspace="dashboard"
        allowEdit={["web", "desktop"].includes(
          teamsContext.app?.host?.clientType
        )}
        appScope="personal"
        maskApp={!readyToShow}
        onLogout={() => {
          logout();
          window.location.reload();
        }}
        onAuthPrompt={() => {
          setShowLoginPrompt(true);
        }}
        onNavigateScreen={(screen) => {
          if (screen === "dashboard:recent") {
            setReadyToShow(true);
          }
        }}
      />
    </Layout>
  );
}

export default PersonalIndexPage;
