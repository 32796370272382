import { gql } from "@apollo/client";

export const GET_WORKSPACE_INFO = gql`
  query WorkspaceInfo($documentId: String!) {
    document(id: $documentId) {
      id
      name
      description
      type
      lastUpdated
      status
      image {
        svg
      }
      collaborators {
        user {
          firstName
          avatar {
            url
            filename
          }
          email
        }
      }
    }
  }
`;
