import React, { useEffect, useMemo, useState, Children, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";
import Spinner from "./Spinner";
import { useWorkspaceSearch } from "../hooks/useWorkspaceSearch";

export default function WorkspaceSearch({
  gqlQuery,
  gqlVars = {},
  filterFn = undefined,
  children,
}) {
  const [isSearchValid, setIsSearchValid] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debounceSearch = useMemo(() => debounce(handleInput, 800), []);

  const { isLoading, isOpen, hasSugessions, workspaces } = useWorkspaceSearch(
    gqlQuery,
    { validity: isSearchValid, params: gqlVars, filter: searchQuery, filterFn }
  );

  useEffect(() => {
    return debounceSearch.cancel;
  });

  function handleInput(searchStr) {
    setIsSearchValid(!!searchStr);
    setSearchQuery(searchStr);
  }

  function clearQuery() {
    setIsSearchValid(false);
    setSearchQuery("");
  }

  function renderResults(children) {
    if (isLoading)
      return (
        <div className="flex items-center justify-center bg-gray-50 rounded-md dark:bg-zinc-800 dark:text-creately-theme-black transition-colors p-1">
          <Spinner text="Finding Workspaces..." />
        </div>
      );
    if (!hasSugessions)
      return (
        <p className="text-sm text-center font-bold text-creately-neutrals-xxdark bg-gray-50 rounded-md dark:bg-zinc-800 dark:text-creately-theme-black p-1">
          No Workspaces Found
        </p>
      );
    return Children.map(
      children({ workspaces, clear: clearQuery }),
      (childNode, idx) => <Menu.Item key={idx}>{childNode}</Menu.Item>
    );
  }

  // TODO: sanitize input

  return (
    <Menu as="div" className="relative">
      <div className="flex items-center space-x-2 dark:bg-zinc-800 dark:text-creately-theme-black rounded-sm p-1">
        <MagnifyingGlassIcon className="w-4 h-4 dark:text-creately-theme-black" />
        <input
          type="text"
          className="flex-grow bg-transparent text-sm outline-none focus:outline-none"
          placeholder="Search Workspaces"
          onChange={(evt) => debounceSearch(evt.target.value)}
        />
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute top-full left-0 w-full shadow mt-2 z-10"
          static
        >
          <div className="flex flex-col space-y-2 p-2 bg-creately-neutrals-xlight dark:bg-zinc-800 rounded-md">
            {renderResults(children)}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
