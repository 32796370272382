import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, isTokenValid: isValid } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isValid && !loggedIn)
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
