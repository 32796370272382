import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Layout from "../common/app.layout";
import { GET_WORKSPACE_INFO } from "../queries/editor";
import { useTeams } from "../hooks/useTeams";
import { useEffect, useMemo } from "react";
import Spinner from "../components/Spinner";
import CreatelyEditorFrameOld from "../components/CreatelyEditorFrameOld";
import useQueryParams from "../hooks/useQueryParams";
import { pages } from "@microsoft/teams-js";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import RightSidebar from "../components/RightSidebar";

function ApplicationEditorPage() {
  const { workspaceId } = useParams();
  const [{ teamsContext, isInitializing }] = useTeams();
  const qs = useQueryParams();
  const teamsWindowContext = useMemo(
    () => getTeamsWindowContext(teamsContext),
    [teamsContext]
  );

  useEffect(() => {
    if (
      !isInitializing &&
      !["sidePanel", "meetingStage"].includes(teamsContext.page.frameContext)
    ) {
      console.log(teamsContext.page.frameContext);
      pages.setCurrentFrame(
        process.env.NODE_ENV === "development"
          ? {
              websiteUrl: `http://localhost:4200/d/${workspaceId}`,
              contentUrl: `${process.env.REACT_APP_CREATELY_APP_HOST}/app/${workspaceId}`,
            }
          : {
              websiteUrl: `${process.env.REACT_APP_NUCLEUS_HOST}/d/${workspaceId}`,
              contentUrl: `${process.env.REACT_APP_CREATELY_APP_HOST}/app/${workspaceId}`,
            }
      );
    }
  }, [teamsContext, workspaceId, isInitializing]);

  const { loading, data: documentInfoResponse } = useQuery(GET_WORKSPACE_INFO, {
    variables: { documentId: workspaceId },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner text="Loading Creately Editor" />
      </div>
    );
  }

  if (
    documentInfoResponse &&
    teamsWindowContext === TEAMS_WINDOW_CONTEXT.meeting.sidepanel
  ) {
    return (
      <Layout>
        <RightSidebar workspace={documentInfoResponse.document} />
      </Layout>
    );
  }

  return (
    <Layout>
      <CreatelyEditorFrameOld
        workspace={documentInfoResponse.document}
        allowEdit={["web", "desktop"].includes(
          teamsContext.app?.host?.clientType
        )}
        contextId={qs.get("aiCxt")}
      />
    </Layout>
  );
}

export default ApplicationEditorPage;
