import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { REGIONS } from "../helpers/utils";

function AppSwitcher({ region, onChange }) {
  const [selectedRegion, setSelectedRegion] = useState(region);

  return (
    <Listbox
      value={selectedRegion}
      onChange={(code) => {
        const newRegion = REGIONS.find((r) => r.code === code);
        setSelectedRegion(newRegion);
        onChange(newRegion);
      }}
    >
      <div className="relative mt-1">
        <Listbox.Button className="relative flex items-center text-blue-800 hover:bg-blue-200 hover:border-blue-400 border text-sm font-bold hover:underline hover:bg-opacity-95 duration-150 transition-colors p-1 ml-2">
          <span className="block truncate uppercase">
            {selectedRegion.code}
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full overflow-auto top-0 left-full ml-1 bg-creately-neutrals-xxlight py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {REGIONS.map((region) => (
              <Listbox.Option
                className={({ active }) =>
                  `relative cursor-default select-none py-1 uppercase text-center hover:text-creately-primary-dark" hover:bg-creately-primary-xlight ${
                    active
                      ? "text-creately-primary-dark"
                      : "bg-creately-neutrals-xxlight"
                  }`
                }
                key={region.id}
                value={region.code}
              >
                {region.code}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default AppSwitcher;
