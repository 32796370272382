import { dateSince } from "../helpers/utils";
import DocumentStatusTag from "./DocumentStatusTag";
import { MinusCircleIcon } from "@heroicons/react/20/solid";

export default function PinnedWorkspace({ workspace, onUnpick }) {
  return (
    <div className="flex items-center w-full relative py-4 shadow-md border rounded overflow-hidden bg-white border-gray-200 dark:bg-zinc-800 dark:border-none">
      <div className="flex-grow">
        <div className="flex items-center">
          <h5 className="text-sm px-4 text-gray-900 dark:text-creately-neutrals-xlight dark:text-opacity-70 font-semibold tracking-tight">
            {workspace.name}
          </h5>
          <DocumentStatusTag status={workspace.status} />
        </div>
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center">
            <h6 className="flex items-center text-gray-400 dark:text-creately-neutrals-xlight dark:text-opacity-50">
              <span className="text-xs font-medium">
                {dateSince(workspace.lastUpdated)}
              </span>
            </h6>
          </div>
        </div>
      </div>
      <button
        className="text-creately-semantics-red-xlight hover:text-creately-status-archived"
        onClick={onUnpick}
      >
        <MinusCircleIcon className="w-8 h-8 mx-1" />
      </button>
    </div>
  );
}
