import { dateSince, getPlural } from "../helpers/utils";
import classnames from "classnames";
import { CheckIcon } from "@heroicons/react/24/outline";
import DocumentStatusTag from "./DocumentStatusTag";

export default function WorkspaceCard(props) {
  const { workspace, selected = false, loading = false } = props;

  return (
    <div className="w-full relative">
        <div className={classnames({
              "flex shadow-md border rounded overflow-hidden py-4 pr-1": true,
              "bg-white dark:bg-zinc-800 border-gray-200 dark:border-creately-neutrals-xxdark dark:hover:border-creately-neutrals-xlight dark:shadow-lg hover:bg-creately-primary-xxlight dark:hover:bg-zinc-700":
                !selected,
              "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-700 dark:border-creately-neutrals-light":
                selected,
            })}>
          <div className="flex flex-col gap-1 w-full">
            <div className="flex items-center text-ellipsis">
              <h5 className="text-sm px-4 text-gray-900 dark:text-creately-neutrals-xlight dark:text-opacity-70 font-semibold tracking-tight truncate">
                {workspace.name}
              </h5>
              <DocumentStatusTag status={workspace.status} />
            </div>
            <div className="flex items-center justify-between px-4">
              <div className="flex items-center">
                <h6 className="flex items-center text-gray-400 dark:text-creately-neutrals-xlight dark:text-opacity-50">
                  <span className="text-xs font-medium">
                    {`Edited ${dateSince(workspace.lastUpdated)}`}
                  </span>
                </h6>
                {/* <span className="inline-block mx-2 border-l border-gray-300 h-3"></span>
                  <h6 className="flex items-center text-gray-400">
                    <span className="text-xs font-medium">Folder Name</span>
                  </h6> */}
              </div>
              <div className="items-center hidden sm:block">
                <h6 className="flex items-center text-gray-400 dark:text-opacity-50">
                  <span className="text-xs font-medium">
                    {getPlural(workspace.collaborators.length, "Collaborator")}
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        {selected && (
          <span className="inline-block absolute top-1 right-1 z-10 text-creately-primary-dark dark:text-creately-neutrals-light">
            <CheckIcon className="w-6 h-6 mx-1" />
          </span>
        )}
        {loading && (
          <span className="inline-block absolute top-2 right-2 z-10 w-4 h-4 border-2 border-creately-theme-skin spinner"></span>
        )}

    </div>
  );
}
