import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import React from "react";
import WorkspaceCard from "./WorkspaceCard";
import Dropdown from "./Dropdown";
import { CREATE_WORKSPACE_COPY, DELETE_WORKSPACE } from "../queries/personal";
import { useMutation } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useNotifications } from "../contexts/notifications.context";
import ErrorNotification from "./ErrorNotification";
import { useTelemetry } from "../contexts/tacker.context";
import { getNotifications } from "../helpers/utils";
import { BrowserView } from "react-device-detect";
import { useAuth } from "../contexts/auth.context";

export default function WorkspaceCardList({
  workspaces,
  dashboardPath,
  placeholderText,
  contextQuery,
}) {
  const isInChannel = dashboardPath?.includes('channel');
  const { folderId } = useParams();
  const folderForDuplicate = folderId || 'home';
  const [ loadingDiagram, setLoadingDiagram ] = useState( null );
  const { show } = useNotifications();
  const { telemetry } = useTelemetry();
  const { user } = useAuth();

  const [documentCopy] = useMutation(CREATE_WORKSPACE_COPY);
  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE);

  async function deleteDiagram(e, doc) {
    e.stopPropagation();
    setLoadingDiagram(doc.id);
    onDropdownClick(null);
    await deleteWorkspace({
      variables: {
        id: doc.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: contextQuery,
      onCompleted: () => {
        setLoadingDiagram(null)
        telemetry.track("workspace.delete", {
          value1Type: "Location",
          value1: dashboardPath,
        });
      },
      onError: (error) => {
        setLoadingDiagram(null);
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) =>
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          )
        );
      },
    });
  }

  async function duplicateDiagram(e, doc) {
    e.stopPropagation();
    setLoadingDiagram(doc.id);
    onDropdownClick(null);
    await documentCopy({
      variables: {
        fromId: doc.id,
        name: 'Copy of ' + doc.name,
        projectId: folderForDuplicate,
      },
      refetchQueries: contextQuery,
      awaitRefetchQueries: true,
      onCompleted: () => {
        setLoadingDiagram(null);
        telemetry.track("workspace.duplicate", {
          value1Type: "Location",
          value1: dashboardPath,
        });
      },
      onError: (error) => {
        setLoadingDiagram(null);
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) =>
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          )
        );
      },
    });
    return document.id;
  }

  const dropdownItems = [
    {
      id: 0,
      label: 'Delete',
      requiredPermission: 0,
      onClick: (e, doc) => deleteDiagram(e, doc),
    },
    {
      id: 1,
      label: 'Duplicate',
      requiredPermission: 5,
      onClick: (e, doc) => duplicateDiagram(e, doc),
    },
  ]

  /**
   * Function to show/hide based on whether the user's requiredPermission
   * to perform the action
   */
  function hasPermissions( requiredPermission, doc ) {
    const userPermission = doc.collaborators.find( c => c.user.id === user.id );
    return userPermission?.user.role <= requiredPermission;
  }

  function getDropdownItems(doc) {
    return dropdownItems.filter( item => item.requiredPermission >= 0 ? hasPermissions(item.requiredPermission, doc) : true );
  }

  const [ dropdownIsOpen, setDropdownIsOpen ] = useState( null );
  function onDropdownClick(docId) {
    setDropdownIsOpen(docId);
  }

  if (workspaces.length === 0) {
    if ( !isInChannel ) {
      return (
        <h4 className="text-center text-sm mt-6 text-creately-neutrals">
          {placeholderText[0]}
        </h4>
      );
    } else if ( isInChannel ) {
      return (
        <div className="space-y-2">
          {placeholderText.map((text, index) => (
            <div key={index} className="flex gap-1 text-creately-neutrals">
              <PaperAirplaneIcon className="w-4 h-4 rotate-45"/>
              <h4 className="text-sm">{text}</h4>
            </div>
          ))}
        </div>
      );
    }
  }
  
  return (
    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      {workspaces.map((doc) => (
          <div key={doc.id} className="group/card relative">
            <Link
              to={{
                pathname: `/app/${doc.id}`,
                state: { dashboardPath },
              }}
            >
              <WorkspaceCard loading={loadingDiagram === doc.id} workspace={doc}/>
            </Link>
            <BrowserView>
              <div className="absolute right-2 top-2 flex flex-col items-end">
                {(loadingDiagram !== doc.id) && (<Dropdown doc={doc} keepOpen={dropdownIsOpen} onOpen={onDropdownClick} menuItems={getDropdownItems(doc)} menuIcon="vertical-dots"></Dropdown>)}
              </div>
            </BrowserView>
          </div>
      ))}
    </div>
  );
}
