import { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

const NotificationsContext = createContext();

export default NotificationsContext;

export function NotificationsProvider({ children, theme }) {
  function promptJSX(jsxContent, type = "info") {
    if (type === "custom") {
      toast(jsxContent);
      return;
    }
    toast[type](jsxContent);
  }

  return (
    <NotificationsContext.Provider value={{ show: promptJSX }}>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        theme={theme}
      />
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => useContext(NotificationsContext);
