import { MinusIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

export default function TemplateThumbnailListItem({ template, selected }) {
  if (!template) return null;

  return (
    <div
      className={classNames({
        "flex overflow-hidden sm:flex-row min-w-md sm:w-auto text-creately-primary-dark p-3 h-24 tmod:h-28 bg-creately-neutrals-xxxlight border shadow rounded-md hover:border-creately-primary-dark dark:bg-zinc-700 dark:border-none dark:text-white dark:hover:bg-zinc-700 dark:border-creately-neutrals-xxxl dark:hover:border-creately-neutrals-xlight": true,
        "bg-white dark:bg-zinc-800 border-gray-200 dark:border-creately-neutrals-xlight dark:hover:border-creately-neutrals-xlight dark:shadow-lg hover:bg-creately-primary-xxlight dark:hover:bg-zinc-700":
          !selected,
        "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-700 dark:border-creately-neutrals-light":
          selected,
      })}
    >
      <img
        src={template.imageUrl}
        alt={`Create a new workspace from ${template.title} template`}
        className="self-center block w-14 tmod:w-20"
      />
      <div className="ml-2 flex-grow">
        <h5 className="text-xs tmod:text-sm font-bold text-creately-primary-dark dark:text-white tracking-tight">
          {template.title}
        </h5>
        <div className="tmod:mt-2">
          <ul className="text-gray-400 text-xxs tmod:text-xs">
            {template.facts.map((f, idx) => (
              <li className="flex items-start space-x-1" key={idx}>
                <MinusIcon className="w-3 h-3 min-w-max" />
                <span className="flex-grow">{f.content}</span>
              </li>
              // <p className="text-gray-400 text-xxs flex items-center space-x-1" key={f.name}>
              //   {/* <strong className="hidden tmod:inline">{f.name} :</strong> */}
              //   {idx === 0 ? (
              //     <span className="inline-block">
              //       <InformationCircleIcon className="text-creately-primary-light w-4 h-4" />
              //     </span>
              //   ) : (
              //     <span className="inline-block">
              //       <LightBulbIcon className="text-creately-primary-light w-4 h-4" />
              //     </span>
              //   )}
              //   <span className="flex-grow">{f.content}</span>
              // </p>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
