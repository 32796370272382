import * as base64Decode from "atob";

export default class Token {
  TOKEN_EXPIRATION_TIME_CLAIM = "exp";
  TOKEN_USERID_CLAIM = "uid";
  TOKEN_ISSUER_CLAIM = "iss";
  TOKEN_ISSUER = "creately.com";

  constructor(token = "") {
    this.token = token;
    if (token) {
      this.decodeTokenClaims();
    }
  }

  /**
   * Extract the claims part of the Gravity JWT token
   */
  decodeTokenClaims() {
    this.decodedClaims = JSON.parse(base64Decode(this.getTokenClaims()));
  }

  /**
   * Return the claims part of the JWT token
   * @return claim part of the token
   */
  getTokenClaims() {
    const claims = this.token.split(".");
    return claims[1];
  }

  /**
   * Return requested claims property value from the JWT claims,
   * valid claim properties include
   * - iat: issued at
   * - exp: expire at
   * - iss: issuer
   * - uid: user id the token issued to
   * @param claim Name of the JWT claims property to retriev
   * @return requested claims property
   */
  getClaim(claim) {
    return this.decodedClaims[claim];
  }

  /**
   * Return current unix time stamp as elapsed seconds
   * @return current unix time stamp
   */
  getCurrentUnixTimestamp() {
    return Date.now() / 1000;
  }

  /**
   * Check if current token is expired by examining the token property 'exp'
   * @return boolean to indicate if the token is expired or not
   */
  isExpired() {
    const expireTimestamp = this.getClaim(this.TOKEN_EXPIRATION_TIME_CLAIM);
    return expireTimestamp <= this.getCurrentUnixTimestamp();
  }

  /**
   * Check if the user id exists on the token claims
   * @return boolen indicating if the user id exist of not
   */
  doesUIDExist() {
    const uid = this.getClaim(this.TOKEN_USERID_CLAIM);
    return uid && typeof uid == "string";
  }

  /**
   * Check if the token issuer is creately or not
   * @return boolean indicating the issuer is creately or not
   */
  doesIssuerValid() {
    return this.TOKEN_ISSUER === this.getClaim(this.TOKEN_ISSUER_CLAIM);
  }

  /**
   * Check if current token is a valid token, to a token to be valid
   * - token must not be expired
   * - uid claim must exist
   * - iss claim must creately.com
   * @return boolean indicating the validity of the token
   */
  isValid() {
    if (!this.token) return false;
    return !this.isExpired() && this.doesUIDExist() && this.doesIssuerValid();
  }

  /**
   * Return the token expiration time as a unix time stamp
   * @return number
   */
  getExpirationTime() {
    return this.getClaim(this.TOKEN_EXPIRATION_TIME_CLAIM);
  }
}
