import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import classnames from "classnames";
import { Fragment } from "react";

export default function Dropdown({
    menuItems,
    menuTitle,
    menuIcon,
    keepOpen,
    onOpen,
    doc,
}) {
    keepOpen = keepOpen === doc.id;
    
    if ( menuItems.length === 0 ) { return null; }

    return (
        <div tabIndex={0} className={classnames({
            "group/more-opts": true,
            "invisible group-hover/card:visible": !keepOpen,
            "visible": keepOpen,
        })}>
            <Menu as="div">
                <Menu.Button className={classnames({
                    "rounded py-1 group-hover/more-opts:bg-blue-100": true,
                    "bg-blue-100": keepOpen,
                })}>
                    <EllipsisVerticalIcon className="h-4 w-4" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    beforeEnter={()=> onOpen(doc.id)}
                    beforeLeave={()=> onOpen(null)}
                >
                    <Menu.Items className="text-sm flex flex-col gap-2 bg-creately-theme-beige dark:bg-zinc-800 p-3 rounded shadow-md border absolute z-10 right-0">
                        {menuItems.map((mItem) => (
                            <Menu.Item key={mItem.id} onClick={(e) => mItem.onClick(e, doc)}>
                                <button className="text-left">{mItem.label}</button>
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
