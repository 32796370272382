import { useLazyQuery } from "@apollo/client";
import { get } from "lodash";
import { useEffect, useState } from "react";

export const useWorkspaceSearch = (
  gqlQuery,
  { validity: isValid, params: variables, filter: searchStr, filterFn: responseFilter }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [findWorkspaces, { loading, data: foundWorkspaces }] = useLazyQuery(
    gqlQuery,
    { fetchPolicy: "cache-first" }
  );

  useEffect(() => {
    if (isValid) {
      findWorkspaces({ variables: { name: searchStr.trim(), ...variables } });
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isValid, searchStr, findWorkspaces, variables]);

  const workspaces = get(foundWorkspaces, "searchDocument", []).filter(
    responseFilter ? responseFilter : () => true
  );

  return {
    hasSugessions: workspaces.length > 0,
    isLoading: loading,
    isOpen,
    workspaces,
  };
};
