import { formatDistanceToNow } from "date-fns";
import pluralize from "pluralize";
import { executeDeepLink } from "@microsoft/teams-js";
import {
  getGqlErrors,
  isNetworkError,
  isOperationError,
} from "./gqlClientHelper";
import { get } from "lodash";

export const dateSince = (timestampString) => {
  if (!timestampString)
    return formatDistanceToNow(new Date(), { addSuffix: true });
  return formatDistanceToNow(Number(timestampString), { addSuffix: true });
};

export const getAppUrl = (path) => {
  return window.location.protocol + "//" + window.location.host + path;
};

export const getInitials = (fullName) => {
  const [firstName, lastName] = fullName.split(" ");

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
};

export const getPlural = (count, word) => {
  return pluralize(word, count, true);
};

export function gotoDeepLink(
  context,
  entityId = "com.cinergix.creately.personal",
  webUrl = "https://cgx-msteams.ngrok.io"
) {
  var encodedWebUrl = encodeURI(webUrl);
  var encodedContext = encodeURI(JSON.stringify(context));
  var taskItemUrl = `https://teams.microsoft.com/l/entity/5350df88-593e-4aa6-a05c-5675edf4177e/${entityId}/?webUrl=${encodedWebUrl}&context=${encodedContext}`;

  executeDeepLink(taskItemUrl);
}

const NOTIFICATION_MAP = {
  ECONNRESET: ["Connection Error", "Please try again", "custom"],
  1602: [
    "Insufficient permissions",
    "You don't have enough permissions to perform this action",
    "custom",
  ],
  1603: [
    "Plan limit exceeded",
    "You have exceeded your plan's limit for creating workspaces.",
    "custom",
  ],
  1604: [
    "Plan limit exceeded",
    "You have exceeded your plan's limit for creating private workspaces.",
    "custom",
  ],
  1605: [
    "Insufficient permissions",
    "Your plan does't have permission to share with users.",
    "custom",
  ],
  1606: [
    "Insufficient permissions",
    "Your plan doesn't have permission to share a folder with users.",
    "custom",
  ],
  1608: [
    "Plan limit exceeded",
    "You have exceeded your plan's limit for creating folders.",
    "custom",
  ],
  1610: [
    "Insufficient permissions",
    "Your plan doesn't have permission to share folders.",
    "custom",
  ],
  1622: [
    "Plan limit exceeded",
    "You have exceeded your plan's limit for creating data models.",
    "custom",
  ],
  1623: [
    "Plan limit exceeded",
    "You have exceeded your plan's limit for creating workspaces.",
    "custom",
  ],
  1624: [
    "Plan limit exceeded",
    "You have exceeded your plan's limit for creating folders.",
    "custom",
  ],
  401: [
    "Unauthorized action",
    "Your account is not authorized to perform this action",
    "warn",
  ],
  409: [
    "Insufficient permission",
    "You are not allowed to perform this action",
    "warn",
  ],
  500: ["Application error", "Something went wrong", "error"],
  INTERNAL_SERVER_ERROR: ["Application error", "Something went wrong", "error"],
};

export function getNotifications(error) {
  if (isNetworkError(error)) {
    return ["Check your network connection", "error"];
  }

  return getGqlErrors(error).map(({ extensions }) => {
    if (isOperationError(extensions?.exception)) {
      return NOTIFICATION_MAP[extensions?.exception?.code];
    }
    return (
      NOTIFICATION_MAP[extensions?.code] ??
      NOTIFICATION_MAP[extensions?.exception?.http]
    );
  });
}

export const backGIFPopup = [
  {
    id: 0,
    // heading: 'Heading',
    // description: 'Description',
    image: "/images/add-to-channel-1.gif",
  },
  {
    id: 1,
    // heading: 'Heading',
    // description: 'Description',
    image: "/images/add-to-channel-2.gif",
  },
];

export const shareGIFPopup = [
  {
    id: 0,
    // heading: 'Heading',
    // description: 'Description',
    image: "/images/add-to-meeting-1.gif",
  },
  {
    id: 1,
    // heading: 'Heading',
    // description: 'Description',
    image: "/images/add-to-meeting-2.gif",
  },
];

export const dashboardImages = [
  { id: 0, image: "/images/dashboard-0.png" },
  { id: 1, image: "/images/dashboard-1.png" },
  { id: 2, image: "/images/dashboard-2.png" },
  { id: 3, image: "/images/dashboard-3.png" },
];

export const fetcher = async (...args) => {
  const res = await fetch(...args);

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.data = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export function getDiagramType(type) {
  return ["concept-map", "flowchart", "mindmap"].includes(type)
    ? "elk"
    : "generic";
}

export const AI_TEMPLATE_MAP = {
  flowchart: {
    title: "Flowchart",
    imageUrl: "/images/thumbnails/flowchart.png",
    description:
      "A flowchart is a visual representation of the sequence of steps in a process. It is used to illustrate and analyze the flow of activities in a systematic manner.",
    facts: [
      {
        name: "👉🏼 When to Use",
        content: "To visualize the sequence of steps in a process.",
      },
      {
        name: "💡 How They Help",
        content: "Highlights each step and identifies potential issues.",
      },
    ],
    templateId: "i5kIigYVwIN",
  },
  mindmap: {
    title: "Mindmap",
    imageUrl: "/images/thumbnails/mindmap.png",
    description:
      "A mindmap is a diagram used to organize and relate multiple ideas or concepts. It provides a structured overview of interconnected thoughts and can be a useful tool for brainstorming and planning.",
    facts: [
      {
        name: "👉🏼 When to Use",
        content: "To organize and relate multiple ideas.",
      },
      {
        name: "💡 How They Help",
        content: "Provides a structured overview of interconnected thoughts.",
      },
    ],
    templateId: "eMajdVoUbge",
  },
  "swot-diagram": {
    title: "SWOT diagram",
    imageUrl: "/images/thumbnails/swot.png",
    description:
      "A SWOT diagram is used to analyze strengths, weaknesses, opportunities, and threats for a business, project, or situation. It helps in making informed decisions by identifying internal and external factors.",
    facts: [
      {
        name: "👉🏼 When to Use",
        content:
          "To analyze strengths, weaknesses, opportunities, and threats.",
      },
      {
        name: "💡 How They Help",
        content:
          "Facilitates a balanced view of positives and negatives for decision-making.",
      },
    ],
    templateId: "DAEyGu8oaCe",
  },
  "soar-diagram": {
    title: "SOAR Model",
    imageUrl: "/images/thumbnails/soar.png",
    description:
      "A tool used to identify and leverage an organization's strengths and opportunities to achieve positive outcomes and future goals.",
    facts: [
      {
        name: "👉🏼 When to Use",
        content: "To focus on positive aspects and future plans.",
      },
      {
        name: "💡 How They Help",
        content:
          "Encourages envisioning and achieving potential based on strengths.",
      },
    ],
    templateId: "oNx8v5ublOw",
  },
  // "lean-ux-canvas": {
  //   title: "Lean UX Canvas",
  //   imageUrl: "/images/thumbnails/leanux.png",
  //   description:
  //     "A collaborative framework used to define and iterate on the user experience design of a product or service, emphasizing user-centricity and rapid prototyping.",
  //   facts: [
  //     {
  //       name: "👉🏼 When to Use",
  //       content:
  //         "To collaboratively define and iterate on the user experience design of a product or service.",
  //     },
  //     {
  //       name: "💡 How They Help",
  //       content:
  //         "Facilitates cross-functional collaboration, user-centric design, and rapid prototyping.",
  //     },
  //   ],
  //   templateId: "34YcYkdaBIb",
  // },
  "business-model-canvas": {
    title: "Business Model Canvas",
    imageUrl: "/images/thumbnails/bmc.png",
    description:
      "The Business Model Canvas is a strategic management tool that provides a holistic view of essential business components. It helps in understanding, designing, and improving a business model.",
    facts: [
      {
        name: "👉🏼 When to Use",
        content: "To understand or plan key parts of a business.",
      },
      {
        name: "💡 How They Help",
        content: "Gives a holistic view of essential business components.",
      },
    ],
    templateId: "NA7730NDfxV",
  },
  kanban: {
    title: "Kanban Board",
    imageUrl: "/images/thumbnails/kanban.png",
    description:
      "A visual system that helps manage work processes and tasks efficiently, promoting a continuous flow of work.",
    facts: [
      {
        name: "When to Use",
        content:
          "To manage and visualize work processes and tasks in real-time.",
      },
      {
        name: "How They Help",
        content:
          "Improves workflow efficiency, identifies bottlenecks, and continuous improvement.",
      },
    ],
    templateId: "0Xdjjmkhl8N",
  },
};

export const REGIONS =
  process.env.NODE_ENV === "development"
    ? [
        { id: 1, name: "America - US", code: "us" },
        { id: 2, name: "Europe - EU", code: "eu" },
        { id: 3, name: "Asia Pacific - AU", code: "au" },
        { id: 4, name: "Middle East - UAE", code: "uae" },
        { id: 0, name: "DEV", code: "dev" },
      ]
    : [
        { id: 1, name: "America - US", code: "us" },
        { id: 2, name: "Europe - EU", code: "eu" },
        { id: 3, name: "Asia Pacific - AU", code: "au" },
        { id: 4, name: "Middle East - UAE", code: "uae" },
      ];

export const getRegionalURLs = (region) => {
  console.log("using region --- " + region);
  const regionalConfig = JSON.parse(process.env.REACT_APP_REGIONAL_CONFIG_JSON);
  return get(regionalConfig, region, undefined);
};
