import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { useAuth } from "../contexts/auth.context";
import { useTeams } from "../hooks/useTeams";
import {
  getClientToken,
  getTeamsWindowContext,
  requestConsent,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import Layout from "./app.layout";
import { useTelemetry } from "../contexts/tacker.context";
import MessageModal from "../components/MessageModal";
import { useMutation } from "@apollo/client";
import { CREATE_WORKSPACE_COPY } from "../queries/personal";
import { useHistory } from "react-router-dom";
import { checkIfRegistered } from "../helpers/authHelper";
import { useNotifications } from "../contexts/notifications.context";
import ErrorNotification from "../components/ErrorNotification";
import { useRegionalApp } from "../contexts/regional.context";
import RegionPicker from "../components/RegionPicker";
import LoginHeader from "../components/LoginHeader";

const defaultTemplateId = process.env.REACT_APP_TUTORIAL_WORKSPACE_ID;
const defaultProjectId = "home";
const defaultWorkspaceName = "Welcome to Creately for Microsoft Teams";

export default function LoginPage() {
  const { state } = useLocation();
  const { login, loggedIn } = useAuth();
  const [{ teamsContext, isInitializing }] = useTeams();
  const [showSpinner, setShowSpinner] = useState(false);
  const { telemetry } = useTelemetry();
  const [isOpen, setIsOpen] = useState(false);
  const { show } = useNotifications();
  const { env: regionalEnv, region, changeRegion } = useRegionalApp();

  const [userState, setUserState] = useState({
    type: "none",
    msTeamsEnabled: false,
  });
  const tabContext = getTeamsWindowContext(teamsContext);
  const history = useHistory();
  const [documentCopy] = useMutation(CREATE_WORKSPACE_COPY);

  useEffect(() => {
    if (isInitializing || loggedIn) return;
    const { user: teamsUser } = teamsContext;
    telemetry.identify(teamsUser.id, {});
    telemetry.track("app.load", {
      value1Type: "Visit",
      value1: teamsUser.tenant.id,
      value2Type: "Target",
      value2: teamsUser.userPrincipalName,
    });
    checkIfRegistered(
      regionalEnv.AUTH_HOST,
      teamsUser.userPrincipalName,
      teamsUser.tenant?.id,
      region
    ).then((reg) => {
      setUserState({
        type: reg.isExistingUser ? "existing" : "first-time",
        msTeamsEnabled: reg.integrations?.microsoftTeams,
      });
      if (reg.isExistingUser) {
        changeRegion(reg.userRegion);
      } else if (!reg.isExistingUser && !reg.integrations?.microsoftTeams) {
        // new user, no tenant has setup in ANY region - let user pick region once)
        changeRegion(region);
      } else if (!reg.isExistingUser && reg.integrations?.microsoftTeams) {
        // switch to already setup tenant's region
        changeRegion(reg.integrations?.microsoftTeams?.region);
      }
    });
  }, [
    telemetry,
    teamsContext,
    isInitializing,
    loggedIn,
    regionalEnv,
    region,
    changeRegion,
  ]);

  async function createTemplateWorkspace(
    workspaceName = defaultWorkspaceName,
    templateId = defaultTemplateId
  ) {
    const {
      data: { documentCopy: document },
    } = await documentCopy({
      variables: {
        fromId: templateId,
        name: workspaceName,
        projectId: defaultProjectId,
      },
      onCompleted: () => setIsOpen(true),
    });
    return document.id;
  }

  async function handleLoginAsFirstTimeUser() {
    await handleLogin().then(() => {
      if (tabContext !== TEAMS_WINDOW_CONTEXT.tab.personal) {
        telemetry.track("signin.click");
        history.push(state?.from);
        return Promise.resolve();
      }
      return createTemplateWorkspace().then((workspaceId) => {
        telemetry.track("tutorial.begin");
        history.push({
          pathname: `/app/${workspaceId}`,
          state: {
            starter: false,
            dashboardPath: state?.from,
          },
        });
        return Promise.resolve();
      });
    });
  }

  function handleLoginFailure(error) {
    if (error.errorCode === 403) {
      return askConsent();
    }
    if (error.errorCode === 409) {
      setIsOpen(true);
    }
    if (error.errorCode === 401) {
      show(
        <ErrorNotification
          error={{
            title: "Failed to Authenticate",
            message: error.message,
          }}
        />,
        "error"
      );
    } else if (error.errorCode === 400) {
      show(
        <ErrorNotification
          error={{ title: "Unable to Login", message: error.message }}
        />,
        "warn"
      );
    } else if (error.errorCode === 500) {
      show(
        <ErrorNotification
          error={{ title: "Unexpected Error", message: error.message }}
        />,
        "error"
      );
    }
    telemetry.track("signin.fail", {
      value1Type: "Fail Reason",
      value1: error.message,
      orgId: teamsContext.user.tenant.id,
    });
  }

  async function handleLogin() {
    telemetry.track("signin.click");
    if (tabContext === TEAMS_WINDOW_CONTEXT.tab.meeting) {
      gotoDeepLink({ subEntityId: teamsContext.meeting.id });
      return;
    }
    try {
      setShowSpinner(true);
      console.log("getting token...");
      const teamsToken = await getClientToken();
      console.log("initiate login..");
      await login(
        teamsContext.user.tenant.id,
        teamsContext.user.userPrincipalName,
        teamsToken,
        region
      )
        .catch((error) => {
          handleLoginFailure(error);
          return Promise.reject(`Failed to Login: ${error.message}`);
        })
        .then((userInfo) => {
          telemetry.identify(teamsContext.user.id, {
            value1Type: "Plan ID",
            value1: userInfo.organization.id,
            value2Type: "Target",
            value2: userInfo.id,
          });
          telemetry.track("signin.success", {
            user_type:
              userState.type === "first-time"
                ? "new-teams | new-creately"
                : userState.msTeamsEnabled
                ? "current-teams | current-creately"
                : "new-teams | current-creately",
          });
          return Promise.resolve(userInfo);
        });
    } catch (error) {
      console.log("uh oh!");
      console.log(error);
      if (error === "invalid_grant") {
        const teamsToken = await requestConsent(
          teamsContext.user.tenant.id
        ).then(getClientToken);
        await login(
          teamsContext.user.tenant.id,
          teamsContext.user.userPrincipalName,
          teamsToken
        )
          .catch((error) => {
            if (error.errorCode === 403) {
              return askConsent();
            } else if (error.errorCode === 409) {
              setIsOpen(true);
            }
            telemetry.track("signin.fail", {
              value1Type: "Fail Reason",
              value1: error.message,
              orgId: teamsContext.user.tenant.id,
            });
            return Promise.reject(`Failed to Login: ${error.message}`);
          })
          .then((userInfo) => {
            telemetry.identify(teamsContext.user.id, {
              value1Type: "Plan ID",
              value1: userInfo.organization.id,
              value2Type: "Target",
              value2: userInfo.id,
            });
            telemetry.track("signin.success", {
              user_type:
                userState.type === "first-time"
                  ? "new-teams | new-creately"
                  : userState.msTeamsEnabled
                  ? "current-teams | current-creately"
                  : "new-teams | current-creately",
            });
            return Promise.resolve(userInfo);
          });
      }
    }
  }

  function gotoDeepLink(
    context,
    entityId = "com.cinergix.creately.personal",
    webUrl = "https://cgx-msteams.ngrok.io"
  ) {
    var encodedWebUrl = encodeURI(webUrl);
    var encodedContext = encodeURI(JSON.stringify(context));
    var taskItemUrl = `https://teams.microsoft.com/l/entity/5350df88-593e-4aa6-a05c-5675edf4177e/${entityId}/?webUrl=${encodedWebUrl}&context=${encodedContext}`;

    microsoftTeams.app.openLink(taskItemUrl);
  }

  function askConsent() {
    const scopes = ["https://graph.microsoft.com/User.Read"];
    return microsoftTeams.authentication.authenticate({
      url:
        window.location.origin +
        `/auth-start.html?clientId=${
          process.env.REACT_APP_AZURE_CLIENT_ID
        }&scope=${encodeURIComponent(scopes.join(" "))}`,
      width: 600,
      height: 535,
    });
  }

  if (isInitializing) {
    return <h1>Loading App...</h1>;
  } else if (tabContext === TEAMS_WINDOW_CONTEXT.meeting.sidepanel) {
    return (
      <Layout>
        <div className="flex flex-col space-y-6 sm:space-y-10 items-center px-0 sm:px-2 sm:w-4/5 xl:w-2/3 min-h-screen mx-auto pt-8">
          <LoginHeader />
          {userState.type === "none" && (
            <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
          )}
          {userState.type === "first-time" && (
            <button
              className="text-xs xs:text-sm space-x-3 font-semibold tracking-wide btn-primary"
              onClick={handleLoginAsFirstTimeUser}
            >
              {showSpinner && (
                <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
              )}
              <span>Open Creately</span>
            </button>
          )}
          {userState.type === "existing" && (
            <button
              className="text-xs xs:text-sm space-x-3 font-semibold tracking-wide btn-primary"
              onClick={() => {
                handleLogin().then(() => history.push(state?.from));
              }}
            >
              {showSpinner && (
                <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
              )}
              <span>Get Started</span>
            </button>
          )}
        </div>
      </Layout>
    );
  }

  return (
    <>
      <MessageModal
        title="Action not Permitted!"
        buttonText="Create Workspace"
        visible={isOpen}
        onClose={() => setIsOpen(false)}
      >
        You need to have a Team plan to use Creately inside Microsoft Teams
      </MessageModal>
      <Layout bgClass="bg-white pt-8 px-2">
        <div className="max-w-[1120px] mx-auto min-h-screen lg:w-5/6">
          <LoginHeader />

          <div className="flex items-center justify-center mt-4 lg:mt-10">
            {userState.type === "first-time" && !userState.msTeamsEnabled && (
              <div className="flex items-center space-x-3 lg:w-[520px]">
                <div className="flex-grow">
                  <RegionPicker onChange={changeRegion} />
                </div>
                <button
                  onClick={() => {
                    handleLogin().then(() => history.push(state?.from));
                  }}
                  className="text-sm flex items-center space-x-2 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
                >
                  {showSpinner && (
                    <>
                      <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
                      <span>Continue</span>
                    </>
                  )}
                  {!showSpinner && <span>Continue</span>}
                </button>
              </div>
            )}

            {userState.type === "existing" && (
              <button
                onClick={() => {
                  handleLogin().then(() => history.push(state?.from));
                }}
                className="text-sm flex items-center space-x-2 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
              >
                {showSpinner && (
                  <>
                    <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
                    <span>Getting Started</span>
                  </>
                )}
                {!showSpinner && <span>Get Started</span>}
              </button>
            )}

            {(tabContext === TEAMS_WINDOW_CONTEXT.tab.personal ||
              tabContext === TEAMS_WINDOW_CONTEXT.tab.channel ||
              tabContext === TEAMS_WINDOW_CONTEXT.tab.stageView ||
              tabContext === TEAMS_WINDOW_CONTEXT.meeting.stage) &&
              userState.type === "first-time" &&
              userState.msTeamsEnabled && (
                <button
                  onClick={() => {
                    handleLogin().then(() => history.push(state?.from));
                  }}
                  className="text-sm flex items-center space-x-2 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
                >
                  {showSpinner && (
                    <>
                      <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
                      <span>Getting Started</span>
                    </>
                  )}
                  {!showSpinner && <span>Get Started</span>}
                </button>
              )}
          </div>

          <div className="mt-4">
            <img
              src="./images/login-banner.png"
              alt="Creately helps you run better meetings, collaborate, brainstorm and organize projects visually!"
            />
          </div>
        </div>
      </Layout>
    </>
  );
}
