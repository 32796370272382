import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

function ErrorNotification({ error, type = undefined }) {
  return (
    <div
      className="max-w-md w-full bg-white dark:bg-zinc-800 rounded-lg pointer-events-auto flex"
    >
      <div className="flex-1 w-0 p-1">
        <div className="flex items-start">
          {type === "custom" && (
            <div className="flex flex-col items-center pt-2 px-1">
              <ExclamationTriangleIcon className="w-6 h-6 text-creately-semantics-yellow" />
            </div>
          )}
          <div className="flex-1">
            <p className="font-bold text-gray-900 dark:text-creately-neutrals-xlight">
              {error.title || "Unexpected Error"}
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-creately-theme-beige">{error.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorNotification;
