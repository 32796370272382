import DocumentStatusTag from "../components/DocumentStatusTag";
import { dateSince, getPlural } from "../helpers/utils";

export default function WorkspaceThumbnail({ workspace }) {
  return (
    <div className="w-full">
      <div className="bg-white hover:bg-creately-theme-skin dark:bg-zinc-800 dark:hover:bg-zinc-700 shadow-md border border-gray-200 dark:border-none rounded overflow-hidden pb-3">
        <div className="relative p-1 pb-4 py-6">
          <div className="flex items-center justify-center">
            <img
              className="w-36 h-36"
              src={workspace.image.svg}
              alt="Diagram"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/placeholder.svg";
              }}
            />
          </div>
          <div className="absolute top-2 left-2">
            <DocumentStatusTag status={workspace.status} />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center">
            <h5 className="text-sm px-4 text-gray-900 dark:text-creately-neutrals-xlight dark:text-opacity-70 font-semibold tracking-tight">
              {workspace.name}
            </h5>
          </div>
          <div className="flex items-center justify-between px-4">
            <div className="flex items-center">
              <h6 className="flex items-center text-gray-400 dark:text-creately-neutrals-xlight dark:text-opacity-50">
                <span className="text-xs font-medium">
                  {dateSince(workspace.lastUpdated)}
                </span>
              </h6>
            </div>
            <div className="flex items-center">
              <h6 className="flex items-center text-gray-400 dark:text-creately-neutrals-xlight dark:text-opacity-50">
                <span className="text-xs font-medium">
                  {getPlural(workspace.collaborators.length, "Collaborator")}
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
