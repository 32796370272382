import Layout from "../app.layout";
import useQueryParams from "../../hooks/useQueryParams";
import * as microsoftTeams from "@microsoft/teams-js";
import UpdateContextForm from "../../components/openai/UpdateContextForm";

function UpdateDiagramPage() {
  const query = useQueryParams();

  async function submitTaskModule(updateContext) {
    microsoftTeams.dialog.url.submit(
      {
        verb: "update-diagram-ai-fe",
        payload: query.get("payload"),
        workspaceId: updateContext.workspaceId,
        workspaceTitle: updateContext.workspaceName,
      },
      process.env.REACT_APP_MANIFEST_APP_ID
    );
  }

  return (
    <Layout>
      <UpdateContextForm onUpdate={submitTaskModule} />
    </Layout>
  );
}

export default UpdateDiagramPage;
