import classNames from "classnames";

export default function Spinner({ size, text }) {
  return (
    <div className="flex flex-col items-center">
      <div className={classNames({
              "spinner border-2 border-creately-theme-skin border-t-creately-primary-dark dark:border-t-zinc-800": true,
              "w-4 h-4": size === 'small',
              "w-8 h-8": !size,
            })}
      ></div>
      {text && (<h4 className="text-center text-sm mt-6 text-creately-status-in-progress dark:text-creately-theme-black">
        {text}
      </h4>)}
    </div>
  );
}
