import { useEffect, useRef, useState } from "react";
import Layout from "./app.layout";
import Spinner from "../components/Spinner";

export default function HelpPage(props) {
  const [isHelpPageLoading, setIsHelpPageLoading] = useState(true);
  const frameRef = useRef(null);

  // load first link to iframe on mounted
  useEffect(() => {
    // get first link
    const firstHelpLink = document.querySelector("[data-helplink]");
    frameRef.current.src = firstHelpLink.href;
    frameRef.current.addEventListener("load", handleFrameLoad);
  }, []);

  function handleFrameLoad(evt) {
    setIsHelpPageLoading(false);
  }

  function handleHelpLink(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    frameRef.current.src = evt.target.href;
    setIsHelpPageLoading(true);
  }

  return (
    <Layout bgClass="bg-white">
      <div className="flex bg-white h-screen">
        <div className="hidden lg:block px-6 w-[300px] bg-creately-theme-beige shadow">
          <h2 className="text-gray-900 text-xl font-semibold tracking-wide pt-6 pb-3">
            Need Help?<small className="block">We got you covered!</small>
          </h2>
          <div className="flex items-center justify-center space-x-2 rounded-lg hover:bg-creately-primary-dark hover:bg-opacity-10 py-2 text-creately-theme-black">
            <svg id="nu-ic-help-center" viewBox="0 0 32 32" className="w-7 h-7">
              <path d="M20.523 22.453c-0.002 0-0.005 0-0.008 0-0.15 0-0.29-0.040-0.412-0.109l0.004 0.002-1.685-1.248c-0.686 0.15-1.473 0.236-2.281 0.236-0.049 0-0.099-0-0.148-0.001l0.007 0c-4.267 0-7.808-2.443-7.808-5.44s3.541-5.429 7.808-5.429 7.819 2.443 7.819 5.451c-0.104 1.747-1.105 3.239-2.545 4.030l-0.026 0.013 0.139 1.589c0 0.003 0 0.008 0 0.012 0 0.336-0.183 0.629-0.454 0.786l-0.004 0.002c-0.115 0.067-0.252 0.107-0.399 0.107-0.002 0-0.004 0-0.006-0h0zM18.645 19.936l1.621 1.216-0.149-1.771 0.288-0.171c1.287-0.604 2.192-1.83 2.335-3.279l0.001-0.017c0-2.411-3.029-4.384-6.752-4.384s-6.741 1.973-6.741 4.384 3.029 4.352 6.752 4.352c0.017 0 0.037 0 0.056 0 0.832 0 1.64-0.105 2.411-0.303l-0.067 0.015z"></path>
              <path d="M28.939 14.379h-1.067c0-6.598-5.349-11.947-11.947-11.947s-11.947 5.349-11.947 11.947v0h-1.067c0-7.187 5.826-13.013 13.013-13.013s13.013 5.826 13.013 13.013v0z"></path>
              <path d="M4.267 23.392c-1.826 0-3.307-1.48-3.307-3.307v0-2.933c0.012-1.817 1.488-3.285 3.307-3.285 0 0 0 0 0 0v0c1.058 0.006 1.914 0.862 1.92 1.919v5.707c-0.018 1.049-0.869 1.893-1.919 1.899h-0.001zM4.267 14.933c-1.235 0.006-2.234 1.005-2.24 2.239v2.934c0.012 1.228 1.010 2.219 2.24 2.219 0 0 0 0 0 0v0c0.471 0 0.853-0.382 0.853-0.853v-5.707c-0.012-0.462-0.389-0.832-0.853-0.832-0 0-0 0-0 0v0z"></path>
              <path d="M27.787 23.392c-1.058-0.006-1.914-0.862-1.92-1.919v-5.707c0.006-1.058 0.862-1.914 1.919-1.92h0.001c1.826 0 3.307 1.48 3.307 3.307v0 2.933c0 1.826-1.48 3.307-3.307 3.307v0zM27.787 14.912c-0.471 0-0.853 0.382-0.853 0.853v0 5.707c0 0.471 0.382 0.853 0.853 0.853v0c1.237 0 2.24-1.003 2.24-2.24v0-2.933c-0.018-1.226-1.013-2.213-2.239-2.219h-0.001z"></path>
              <path d="M25.323 29.397h-3.2v-1.067h3.2c1.408 0 2.549-1.141 2.549-2.549v0-2.923h1.067v2.923c0 1.997-1.619 3.616-3.616 3.616v0z"></path>
              <path d="M20.544 30.635h-3.083c-1.083-0.080-1.931-0.978-1.931-2.075s0.848-1.995 1.924-2.074l0.007-0h3.083c0.045-0.003 0.096-0.005 0.149-0.005 1.149 0 2.080 0.931 2.080 2.080s-0.931 2.080-2.080 2.080c-0.052 0-0.104-0.002-0.156-0.006l0.007 0zM17.461 27.552c-0.513 0.054-0.91 0.485-0.91 1.008s0.396 0.954 0.905 1.008l0.004 0h3.083c0.031 0.003 0.067 0.005 0.104 0.005 0.56 0 1.013-0.454 1.013-1.013s-0.454-1.013-1.013-1.013c-0.037 0-0.073 0.002-0.108 0.006l0.004-0z"></path>
              <path d="M14.176 15.552c0 0.501-0.406 0.907-0.907 0.907s-0.907-0.406-0.907-0.907c0-0.501 0.406-0.907 0.907-0.907s0.907 0.406 0.907 0.907z"></path>
              <path d="M16.907 15.552c0 0.501-0.406 0.907-0.907 0.907s-0.907-0.406-0.907-0.907c0-0.501 0.406-0.907 0.907-0.907s0.907 0.406 0.907 0.907z"></path>
              <path d="M19.637 15.552c0 0.501-0.406 0.907-0.907 0.907s-0.907-0.406-0.907-0.907c0-0.501 0.406-0.907 0.907-0.907s0.907 0.406 0.907 0.907z"></path>
            </svg>
            <a
              href="https://support.creately.com"
              target="_blank"
              rel="noreferrer"
            >
              Creately Help Center
            </a>
          </div>

          <div className="flex flex-col justify-center space-y-2 py-4">
            <a
              href={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
              className="rounded bg-creately-primary-dark text-white font-semibold tracking-wide px-3 py-1 mt-4"
              target="_blank"
              rel="noreferrer"
              data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
              onClick={handleHelpLink}
            >
              Explore Features
            </a>
            <a
              href={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
              className="rounded bg-creately-primary-dark text-white font-semibold tracking-wide px-3 py-1 mt-4"
              target="_blank"
              rel="noreferrer"
              data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
              onClick={handleHelpLink}
            >
              Creately Blog
            </a>
          </div>
        </div>

        <div className="lg:hidden bg-creately-theme-beige shadow fixed bottom-0 left-0 w-full z-10">
          <div className="flex">
            <div className="flex items-center justify-center mx-2 space-x-2 rounded-lg hover:bg-creately-primary-dark hover:bg-opacity-10 py-2 text-creately-theme-black">
              <svg
                id="nu-ic-help-center"
                viewBox="0 0 32 32"
                className="w-5 h-5"
              >
                <path d="M20.523 22.453c-0.002 0-0.005 0-0.008 0-0.15 0-0.29-0.040-0.412-0.109l0.004 0.002-1.685-1.248c-0.686 0.15-1.473 0.236-2.281 0.236-0.049 0-0.099-0-0.148-0.001l0.007 0c-4.267 0-7.808-2.443-7.808-5.44s3.541-5.429 7.808-5.429 7.819 2.443 7.819 5.451c-0.104 1.747-1.105 3.239-2.545 4.030l-0.026 0.013 0.139 1.589c0 0.003 0 0.008 0 0.012 0 0.336-0.183 0.629-0.454 0.786l-0.004 0.002c-0.115 0.067-0.252 0.107-0.399 0.107-0.002 0-0.004 0-0.006-0h0zM18.645 19.936l1.621 1.216-0.149-1.771 0.288-0.171c1.287-0.604 2.192-1.83 2.335-3.279l0.001-0.017c0-2.411-3.029-4.384-6.752-4.384s-6.741 1.973-6.741 4.384 3.029 4.352 6.752 4.352c0.017 0 0.037 0 0.056 0 0.832 0 1.64-0.105 2.411-0.303l-0.067 0.015z"></path>
                <path d="M28.939 14.379h-1.067c0-6.598-5.349-11.947-11.947-11.947s-11.947 5.349-11.947 11.947v0h-1.067c0-7.187 5.826-13.013 13.013-13.013s13.013 5.826 13.013 13.013v0z"></path>
                <path d="M4.267 23.392c-1.826 0-3.307-1.48-3.307-3.307v0-2.933c0.012-1.817 1.488-3.285 3.307-3.285 0 0 0 0 0 0v0c1.058 0.006 1.914 0.862 1.92 1.919v5.707c-0.018 1.049-0.869 1.893-1.919 1.899h-0.001zM4.267 14.933c-1.235 0.006-2.234 1.005-2.24 2.239v2.934c0.012 1.228 1.010 2.219 2.24 2.219 0 0 0 0 0 0v0c0.471 0 0.853-0.382 0.853-0.853v-5.707c-0.012-0.462-0.389-0.832-0.853-0.832-0 0-0 0-0 0v0z"></path>
                <path d="M27.787 23.392c-1.058-0.006-1.914-0.862-1.92-1.919v-5.707c0.006-1.058 0.862-1.914 1.919-1.92h0.001c1.826 0 3.307 1.48 3.307 3.307v0 2.933c0 1.826-1.48 3.307-3.307 3.307v0zM27.787 14.912c-0.471 0-0.853 0.382-0.853 0.853v0 5.707c0 0.471 0.382 0.853 0.853 0.853v0c1.237 0 2.24-1.003 2.24-2.24v0-2.933c-0.018-1.226-1.013-2.213-2.239-2.219h-0.001z"></path>
                <path d="M25.323 29.397h-3.2v-1.067h3.2c1.408 0 2.549-1.141 2.549-2.549v0-2.923h1.067v2.923c0 1.997-1.619 3.616-3.616 3.616v0z"></path>
                <path d="M20.544 30.635h-3.083c-1.083-0.080-1.931-0.978-1.931-2.075s0.848-1.995 1.924-2.074l0.007-0h3.083c0.045-0.003 0.096-0.005 0.149-0.005 1.149 0 2.080 0.931 2.080 2.080s-0.931 2.080-2.080 2.080c-0.052 0-0.104-0.002-0.156-0.006l0.007 0zM17.461 27.552c-0.513 0.054-0.91 0.485-0.91 1.008s0.396 0.954 0.905 1.008l0.004 0h3.083c0.031 0.003 0.067 0.005 0.104 0.005 0.56 0 1.013-0.454 1.013-1.013s-0.454-1.013-1.013-1.013c-0.037 0-0.073 0.002-0.108 0.006l0.004-0z"></path>
                <path d="M14.176 15.552c0 0.501-0.406 0.907-0.907 0.907s-0.907-0.406-0.907-0.907c0-0.501 0.406-0.907 0.907-0.907s0.907 0.406 0.907 0.907z"></path>
                <path d="M16.907 15.552c0 0.501-0.406 0.907-0.907 0.907s-0.907-0.406-0.907-0.907c0-0.501 0.406-0.907 0.907-0.907s0.907 0.406 0.907 0.907z"></path>
                <path d="M19.637 15.552c0 0.501-0.406 0.907-0.907 0.907s-0.907-0.406-0.907-0.907c0-0.501 0.406-0.907 0.907-0.907s0.907 0.406 0.907 0.907z"></path>
              </svg>
              <a
                href="https://support.creately.com"
                target="_blank"
                rel="noreferrer"
              >
                Help Center
              </a>
            </div>
            <div className="flex justify-end items-center mx-2 space-x-2 py-2 flex-grow">
              <a
                href={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
                className="text-sm text-center rounded bg-creately-primary-dark text-white font-semibold tracking-wide p-1"
                target="_blank"
                rel="noreferrer"
                data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/tour/?integration=msteams`}
                onClick={handleHelpLink}
              >
                Explore Features
              </a>
              <a
                href={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
                className="text-sm text-center rounded bg-creately-primary-dark text-white font-semibold tracking-wide p-1"
                target="_blank"
                rel="noreferrer"
                data-helplink={`${process.env.REACT_APP_PHOENIX_HOST}/blog/?integration=msteams`}
                onClick={handleHelpLink}
              >
                Creately Blog
              </a>
            </div>
          </div>
        </div>

        <div className="flex-grow relative pb-12 lg:pb-0">
          {isHelpPageLoading && (
            <div className="absolute flex items-center justify-center bg-creately-theme-beige top-0 left-0 right-0 bottom-0">
              <Spinner text="Loading..."></Spinner>
            </div>
          )}
          <iframe
            ref={frameRef}
            className="block w-full h-full"
            frameBorder="0"
            title="Creately Help"
          ></iframe>
        </div>
      </div>
    </Layout>
  );
}
