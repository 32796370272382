import CollabInviteForm from "../components/CollabInviteForm";
import Layout from "./app.layout";

export default function InvitePage() {
  return (
    <Layout bgClass="bg-white">
      <div className="w-full px-2 sm:px-0 sm:w-4/5 lg:3/5 xl:w-2/5 h-screen pt-8 mx-auto">
        <CollabInviteForm onSubmit={() => {}} />
      </div>
    </Layout>
  );
}
