import React, { useCallback } from "react";

export default function TourControls({ controller: swiper }) {
  const gotoNext = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);

  if (swiper === null) {
    return null;
  }

  if (swiper.isBeginning) {
    return (
      <button
        className="inline-flex justify-center py-2 px-5 text-sm font-medium text-creately-primary-dark bg-creately-primary-dark bg-opacity-10 dark:text-creately-theme-beige dark:bg-opacity-100 border border-creately-primary-dark rounded hover:bg-opacity-20  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        onClick={gotoNext}
      >
        <span>Take Tour</span>
      </button>
    );
  } else if (swiper.isEnd) {
    return null;
  }

  return (
    <button
      className="inline-flex justify-center py-2 px-5 text-sm font-medium text-creately-primary-dark bg-creately-primary-dark bg-opacity-10 dark:text-creately-theme-beige dark:bg-opacity-100 border border-creately-primary-dark rounded hover:bg-opacity-20  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      onClick={gotoNext}
    >
      <span>Next</span>
    </button>
  );
}
