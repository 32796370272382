import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useMemo } from "react";
import { useAuth } from "../contexts/auth.context";
import { useRegionalApp } from "../contexts/regional.context";

const GQLClientProvider = (props) => {
  const { isTokenValid: isValid, tokenCookie: authCookie } = useAuth();
  const { env: regionalEnv } = useRegionalApp();

  const gqlClient = useMemo(() => {
    const httpLink = new HttpLink({
      uri: regionalEnv.API_HOST,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "creately-gravity-token": isValid && authCookie ? authCookie : "",
        },
      };
    });
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [isValid, authCookie, regionalEnv]);

  return <ApolloProvider client={gqlClient} {...props} />;
};

function getGqlErrors(error) {
  const result = [];

  if (hasPopulatedGqlErrors(error)) {
    result.push(...error.graphQLErrors);
  }

  if (hasUnpopulatedGqlErrors(error)) {
    result.push(...error.networkError.result.errors);
  }

  return result;
}

function hasGqlErrors(error) {
  return hasPopulatedGqlErrors(error) || hasUnpopulatedGqlErrors(error);
}

function isNetworkError(error) {
  return hasNetworkError(error) && !hasGqlErrors(error);
}

function hasPopulatedGqlErrors(error) {
  return Boolean(
    error &&
      typeof error === "object" &&
      Array.isArray(error.graphQLErrors) &&
      error.graphQLErrors.length
  );
}

function hasUnpopulatedGqlErrors(error) {
  return Boolean(
    hasNetworkError(error) &&
      "result" in error.networkError &&
      Array.isArray(error.networkError.result.errors) &&
      error.networkError.result.errors.length
  );
}

function hasNetworkError(error) {
  return Boolean(error && typeof error === "object" && error.networkError);
}

function isOperationError(err) {
  return (
    err &&
    typeof err === "object" &&
    "id" in err &&
    "code" in err &&
    "http" in err
  );
}

export { GQLClientProvider, getGqlErrors, isNetworkError, isOperationError };
