import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import TemplateThumbnailListItem from "../TemplateThumbnailListItem";
import { AI_TEMPLATE_MAP } from "../../helpers/utils";

const GenerateContextSchema = Yup.object().shape({
  conversationScope: Yup.string()
    .oneOf(["all", "specific"])
    .required("Conversation context is required"),
  conversationLength: Yup.number().when("conversationScope", {
    is: "specific",
    then: Yup.number()
      .min(3, "Must have at least 3 messages")
      .max(10, "Maximum number of messages is 10")
      .required("Number of messages is required"),
  }),
  templateType: Yup.string().required(),
});

const AI_TEMPLATE_THUMBNAILS = [
  // {
  //   id: "3R5Q94E6mON",
  //   name: "Concept Map",
  //   thumbnailURL: "/community-templates/brainstorming/3R5Q94E6mON.svg",
  //   key: "concept-map",
  // },
  {
    id: "eMajdVoUbge",
    name: "Mind Map",
    thumbnailURL: "/community-templates/brainstorming/pixfEuKvcxO.svg",
    key: "mindmap",
  },
  {
    id: "i5kIigYVwIN",
    name: "Flowchart",
    thumbnailURL: "/community-templates/diagrams/dRsCP5IMVWn.svg",
    key: "flowchart",
  },
  {
    id: "oNx8v5ublOw",
    name: "SOAR Model",
    thumbnailURL: "/community-templates/strategy/jl640irm5.svg",
    key: "soar-model",
  },
  {
    id: "34YcYkdaBIb",
    name: "Lean UX Canvas",
    thumbnailURL: "/community-templates/research/jqhlqn953.svg",
    key: "lean-ux-canvas",
  },
  {
    id: "0Xdjjmkhl8N",
    name: "Kanban Board",
    thumbnailURL: "https://svg.template.creately.com/IUNGl08HmDk",
    key: "kanban",
  },
  {
    id: "NA7730NDfxV",
    name: "Business Model Canvas",
    thumbnailURL: "/community-templates/bmc.svg",
    key: "business-model-canvas",
  },
  {
    id: "DAEyGu8oaCe",
    name: "SWOT Diagram",
    thumbnailURL: "/community-templates/swot.svg",
    key: "swot-diagram",
  },
];

export default function GenerateContextForm({ onSubmit }) {
  return (
    <div className="h-full">
      <Formik
        initialValues={{
          conversationScope: "specific",
          conversationLength: 3,
          templateType: "flowchart",
        }}
        validationSchema={GenerateContextSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, values }) => (
          <Form className="flex flex-col h-full">
            <div className="flex-grow">
              <section className="flex items-center md:w-4/5 space-x-2 justify-between">
                <div className="my-3 relative">
                  <h2 className="text-xs font-bold text-creately-primary-xdark dark:text-creately-neutrals-light mb-3">
                    Scope
                  </h2>
                  <Field name="conversationScope">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue },
                      meta,
                    }) => (
                      <>
                        <RadioGroup
                          value={field.value}
                          className="flex space-x-2"
                          onChange={(val) =>
                            setFieldValue("conversationScope", val, true)
                          }
                        >
                          <RadioGroup.Option value="all">
                            {({ checked }) => (
                              <div
                                className={classNames({
                                  "flex h-20 overflow-hidden sm:flex-row min-w-[210px] tmod:min-w-[260px] text-creately-primary-dark p-2 bg-creately-neutrals-xxxlight border shadow rounded-md hover:border-creately-primary-dark dark:bg-zinc-700 dark:border-none dark:text-white dark:hover:bg-zinc-700 dark:border-creately-neutrals-xxxl dark:hover:border-creately-neutrals-xlight": true,
                                  "bg-white dark:bg-zinc-800 border-gray-200 dark:border-creately-neutrals-xlight dark:hover:border-creately-neutrals-xlight dark:shadow-lg hover:bg-creately-primary-xxlight dark:hover:bg-zinc-700":
                                    !checked,
                                  "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-700 dark:border-creately-neutrals-light":
                                    checked,
                                })}
                              >
                                <div>
                                  <h4 className="text-xs tmod:text-sm font-semibold">
                                    Entire Conversation
                                  </h4>
                                  <p className="text-gray-400 text-xxs tmod:text-xs mt-2">
                                    summarize content in entire chat/thread
                                  </p>
                                </div>
                              </div>
                            )}
                          </RadioGroup.Option>
                          <RadioGroup.Option value="specific">
                            {({ checked }) => (
                              <div
                                className={classNames({
                                  "flex h-20 overflow-hidden sm:flex-row min-w-[210px] tmod:min-w-[260px] text-creately-primary-dark p-2 bg-creately-neutrals-xxxlight border shadow rounded-md hover:border-creately-primary-dark dark:bg-zinc-700 dark:border-none dark:text-white dark:hover:bg-zinc-700 dark:border-creately-neutrals-xxxl dark:hover:border-creately-neutrals-xlight": true,
                                  "bg-white dark:bg-zinc-800 border-gray-200 dark:border-creately-neutrals-xlight dark:hover:border-creately-neutrals-xlight dark:shadow-lg hover:bg-creately-primary-xxlight dark:hover:bg-zinc-700":
                                    !checked,
                                  "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-700 dark:border-creately-neutrals-light":
                                    checked,
                                })}
                              >
                                <div>
                                  <h4 className="text-xs tmod:text-sm font-semibold">
                                    Part of Conversation
                                  </h4>
                                  {values.conversationScope !== "specific" && (
                                    <p className="text-gray-400 text-xxs tmod:text-xs mt-2">
                                      only consider a subset of messages in chat
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                          </RadioGroup.Option>
                        </RadioGroup>
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>

                  {values.conversationScope === "specific" && (
                    <div className="flex items-center absolute bottom-2 right-2">
                      <h2 className="text-xs text-creately-primary-xdark dark:text-creately-neutrals-light mr-3">
                        No. of Messages
                      </h2>
                      <Field
                        name="conversationLength"
                        type="number"
                        className="w-[80px] rounded-md text-center outline-none focus:outline-none"
                      />
                      <ErrorMessage
                        name="conversationLength"
                        component="div"
                        className="text-xs text-red-500 dark:text-red-300"
                      />
                    </div>
                  )}
                </div>

                <div className="pb-2 flex justify-center">
                  <button
                    type="button"
                    className="justify-center text-sm font-medium btn-primary dark:text-creately-theme-beige dark:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={submitForm}
                  >
                    Generate Workspace
                  </button>
                </div>
              </section>

              <section className="flex-grow mt-3">
                <h2 className="text-xs font-bold text-creately-primary-xdark dark:text-creately-neutrals-light mb-3">
                  Pick a template type
                </h2>
                <Field name="templateType" type="text">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue },
                    meta,
                  }) => (
                    <>
                      <RadioGroup
                        value={field.value}
                        onChange={(val) =>
                          setFieldValue("templateType", val, true)
                        }
                      >
                        <div className="grid grid-cols-2 gap-2 overflow-y-scroll h-[140px] tmod:h-[280px]">
                          {AI_TEMPLATE_THUMBNAILS.map((template) => (
                            <RadioGroup.Option
                              value={template.key}
                              key={template.id}
                            >
                              {({ checked }) => (
                                <TemplateThumbnailListItem
                                  template={AI_TEMPLATE_MAP[template.key]}
                                  selected={checked}
                                />
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="templateType"
                  component="div"
                  className="invalid-feedback"
                />
              </section>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
