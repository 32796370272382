import { Siddi } from "@creately/siddi";

function createTelemetry(telemetryConfig) {
  const _globals = {};
  const _siddi = new Siddi(telemetryConfig);

  function sendEvent(eventName, eventProperties) {
    try {
      const props = eventProperties ? eventProperties : {};
      const appEventName = `${process.env.REACT_APP_TRACKING_PREFIX}.${eventName}`;
      const partialEventName = eventName.split(".");
      const mergedProperties = {
        url: window.location.pathname,
        location: partialEventName[0],
        subLocation: partialEventName[1],
        ..._globals,
        ...props
      };
      _siddi.track(appEventName, mergedProperties);
    } catch (error) {
      console.error("[TRACKING ERROR]: Unable to send tracking event");
    }
  }

  function identifyUser(userId, userProperties) {
    try {
      _siddi.identify(userId, userProperties);
      // can remove userID ??
      _globals["userId"] = userId;
      // we will send azId to all events after identification
      _globals["azId"] = userId;
    } catch (error) {
      console.error("[TRACKING ERROR]: Unable to identify user");
    }
  }

  return {
    track: sendEvent,
    identify: identifyUser,
  };
}

export { createTelemetry };
