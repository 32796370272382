import { Route, Switch, useRouteMatch } from "react-router-dom";
import ChannelDashboardPage from "./dashboard.page";
import ChannelUpdatesPage from "./updates.page";
import ChannelWorkspacesPage from "./workspaces.page";
import Layout from "../common/app.layout";

function ChannelIndexPage() {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Route exact path={path} component={ChannelDashboardPage} />
        <Route exact path={`${path}/updates`} component={ChannelUpdatesPage} />
        <Route
          exact
          path={`${path}/workspaces`}
          component={ChannelWorkspacesPage}
        />
      </Switch>
    </Layout>
  );
}

export default ChannelIndexPage;
