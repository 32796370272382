import { createContext, useContext, useState } from "react";
import { getRegionalURLs } from "../helpers/utils";

const RegionalAppContext = createContext({ region: undefined, env: undefined });

const RegionalAppProvider = (props) => {
  const [appRegion, setAppRegion] = useState(props.region);

  return (
    <RegionalAppContext.Provider
      value={{
        region: appRegion,
        changeRegion: (newRegion) => {
          if (appRegion !== newRegion) {
            setAppRegion(newRegion);
          }
        },
        env: getRegionalURLs(appRegion),
      }}
      {...props}
    />
  );
};

const useRegionalApp = () => useContext(RegionalAppContext);

export { RegionalAppProvider, useRegionalApp };
