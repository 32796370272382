import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RadioGroup } from "@headlessui/react";
import TemplateThumbnailListItem from "../TemplateThumbnailListItem";
import { AI_TEMPLATE_MAP } from "../../helpers/utils";

const RequireContextSchema = Yup.object().shape({
  conversationSummary: Yup.string().required(
    "Conversation summary is required"
  ),
  templateType: Yup.string().required("Need to pick a template type"),
});

const AI_TEMPLATE_THUMBNAILS = [
  {
    id: "eMajdVoUbge",
    name: "Mind Map",
    thumbnailURL: "/community-templates/brainstorming/pixfEuKvcxO.svg",
    key: "mindmap",
  },
  {
    id: "i5kIigYVwIN",
    name: "Flowchart",
    thumbnailURL: "/community-templates/diagrams/dRsCP5IMVWn.svg",
    key: "flowchart",
  },
  {
    id: "oNx8v5ublOw",
    name: "SOAR Model",
    thumbnailURL: "/community-templates/strategy/jl640irm5.svg",
    key: "soar-diagram",
  },
  // {
  //   id: "34YcYkdaBIb",
  //   name: "Lean UX Canvas",
  //   thumbnailURL: "/community-templates/research/jqhlqn953.svg",
  //   key: "lean-ux-canvas",
  // },
  {
    id: "0Xdjjmkhl8N",
    name: "Kanban Board",
    thumbnailURL: "https://svg.template.creately.com/IUNGl08HmDk",
    key: "kanban",
  },
  {
    id: "NA7730NDfxV",
    name: "Business Model Canvas",
    thumbnailURL: "/community-templates/bmc.svg",
    key: "business-model-canvas",
  },
  {
    id: "DAEyGu8oaCe",
    name: "SWOT Diagram",
    thumbnailURL: "/community-templates/swot.svg",
    key: "swot-diagram",
  },
];

export default function RequireContextForm({ text, onSubmit, defaultType }) {
  console.log(defaultType)
  return (
    <div className="w-full">
      <Formik
        initialValues={{
          conversationSummary: text,
          templateType: defaultType || "mindmap",
        }}
        validationSchema={RequireContextSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, values }) => (
          <Form className="flex flex-col h-full">
            <div className="flex-grow">
              <section className="flex items-start space-x-2 justify-between">
                <div className="my-3 relative flex-grow">
                  <h2 className="text-xs font-bold text-creately-primary-xdark dark:text-creately-neutrals-light mb-3">
                    Describe what you need to include in workspace
                  </h2>
                  <Field
                    as="textarea"
                    className="block w-full h-[140px]"
                    name="conversationSummary"
                  />
                  <ErrorMessage
                    name="conversationSummary"
                    component="div"
                    className="text-sm text-creately-semantics-red-xdark"
                  />
                </div>

                <div className="pb-2 flex justify-center mt-10">
                  <button
                    type="button"
                    className="justify-center text-sm font-medium btn-primary dark:text-creately-theme-beige dark:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={submitForm}
                  >
                    Generate Workspace
                  </button>
                </div>
              </section>

              <section className="flex-grow mt-3">
                <h2 className="text-xs font-bold text-creately-primary-xdark dark:text-creately-neutrals-light mb-3">
                  Pick a template type
                </h2>
                <Field name="templateType" type="text">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue },
                    meta,
                  }) => (
                    <>
                      <RadioGroup
                        value={field.value}
                        onChange={(val) =>
                          setFieldValue("templateType", val, true)
                        }
                      >
                        <div className="grid grid-cols-2 gap-2 overflow-y-scroll h-[140px] tmod:h-[280px]">
                          {AI_TEMPLATE_THUMBNAILS.map((template) => (
                            <RadioGroup.Option
                              value={template.key}
                              key={template.id}
                            >
                              {({ checked }) => (
                                <TemplateThumbnailListItem
                                  template={AI_TEMPLATE_MAP[template.key]}
                                  selected={checked}
                                />
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="templateType"
                  component="div"
                  className="invalid-feedback"
                />
              </section>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
