import * as microsoftTeams from "@microsoft/teams-js";

export const getClientToken = () =>
  microsoftTeams.authentication.getAuthToken();

export const exchangeToken = async (authResult, tenantId) => {
  const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
  const formData = new URLSearchParams();
  formData.set("code", authResult.code);
  formData.set("code_verifier", authResult.codeVerifier);
  formData.set("redirect_uri", authResult.redirectUri);
  formData.set("client_id", process.env.REACT_APP_AZURE_CLIENT_ID);
  formData.set(
    "scope",
    "User.Read openid email https://graph.microsoft.com/User.Read.All"
  );
  formData.set("grant_type", "authorization_code");
  const authResponse = await fetch(tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8;",
    },
    body: formData,
  });
  if (!authResponse.ok)
    throw new Error("Failed to Authenticate - ", authResponse.statusText);
  const tokenResponse = await authResponse.json();
  return tokenResponse["access_token"];
};

export const requestConsent = (tenantId) => {
  return new Promise((resolve, reject) => {
    const scopes = [
      "User.Read",
      "openid",
      "email",
      "https://graph.microsoft.com/User.Read.All",
    ];
    microsoftTeams.authentication
      .authenticate({
        url: `${window.location.origin}/auth-start.html?clientId=${
          process.env.REACT_APP_AZURE_CLIENT_ID
        }&scope=${encodeURIComponent(scopes.join(" "))}`,
        width: 600,
        height: 535,
      })
      .then((authResult) => {
        exchangeToken(authResult, tenantId).then(resolve);
      })
      .catch((reason) => {
        reject(JSON.stringify(reason));
      });
  });
};

export const TEAMS_WINDOW_CONTEXT = {
  config: {
    group: "config.group",
    channel: "config.channel",
    meeting: "config.meeting",
  },
  tab: {
    personal: "tab.personal",
    group: "tab.group",
    channel: "tab.channel",
    meeting: "tab.meeting",
    stageView: "tab.stageView",
  },
  meeting: { sidepanel: "meeting.sidepanel", stage: "meeting.stage" },
};

export const getTeamsWindowContext = (teamsContext) => {
  if (!teamsContext) return "unknown";

  if (teamsContext?.page?.frameContext === "settings") {
    if (teamsContext?.chat?.id && teamsContext?.meeting?.id) {
      return TEAMS_WINDOW_CONTEXT.config.meeting;
    } else if (teamsContext?.team?.id) {
      return TEAMS_WINDOW_CONTEXT.config.channel;
    } else {
      return TEAMS_WINDOW_CONTEXT.config.group;
    }
  } else if (teamsContext?.page?.frameContext === "sidePanel") {
    return TEAMS_WINDOW_CONTEXT.meeting.sidepanel;
  } else if (teamsContext?.page?.frameContext === "meetingStage") {
    return TEAMS_WINDOW_CONTEXT.meeting.stage;
  } else if (teamsContext?.page?.id === "com.cinergix.creately.personal") {
    return TEAMS_WINDOW_CONTEXT.tab.personal;
  } else if (teamsContext?.page?.frameContext === "content") {
    if (teamsContext?.meeting?.id && teamsContext?.chat?.id) {
      return TEAMS_WINDOW_CONTEXT.tab.meeting;
    } else if (teamsContext?.chat?.id) {
      return TEAMS_WINDOW_CONTEXT.tab.group;
    } else if (teamsContext?.channel?.id) {
      return teamsContext?.page?.id
        ? TEAMS_WINDOW_CONTEXT.tab.channel
        : TEAMS_WINDOW_CONTEXT.tab.stageView;
    }
  }
};
