import Layout from "../app.layout";
import useQueryParams from "../../hooks/useQueryParams";
import * as microsoftTeams from "@microsoft/teams-js";
import RequireContextForm from "../../components/openai/RequireContextForm";
import { useRef } from "react";

function RequireContextPage() {
  const query = useQueryParams();
  const { current: userText } = useRef(() => {
    const payload = JSON.parse(query.get("payload"));
    return payload.userText || "";
  });
  const { current: defaultType } = useRef(() => {
    const payload = JSON.parse(query.get("payload"));
    return payload.diagramType || "";
  });

  async function submitTaskModule(generationContext) {
    microsoftTeams.dialog.url.submit(
      {
        verb: "require-context-ai-fe",
        payload: query.get("payload"),
        userText: generationContext.conversationSummary,
        diagramType: generationContext.templateType,
      },
      process.env.REACT_APP_MANIFEST_APP_ID
    );
  }

  return (
    <Layout>
      <div className="flex items-start justify-center w-full h-screen px-4">
        <RequireContextForm
          text={userText()}
          onSubmit={submitTaskModule}
          defaultType={defaultType()}
        />
      </div>
    </Layout>
  );
}

export default RequireContextPage;
