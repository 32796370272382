import { CheckIcon, FolderIcon } from "@heroicons/react/24/outline";
import classnames from "classnames";
export default function FolderCard({ folder, selected }) {
  return (
    <div className="w-full relative">
      <div
        className={classnames({
          "flex items-center justify-center border shadow-md rounded overflow-hidden py-2 pb-3 pl-4": true,
          "bg-white border-gray-200 dark:bg-zinc-800 dark:border dark:border-creately-neutrals-xxdark dark:hover:border-creately-neutrals-xlight hover:bg-creately-primary-xxlight dark:hover:bg-zinc-700": !selected,
          "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-700 dark:border-creately-neutrals-light": selected,
        })}
      >
        <div className="text-gray-500">
          <FolderIcon className="block w-10"/>
        </div>
        <h5 className="flex-grow text-sm px-4 text-gray-900 dark:text-gray-300 dark:text-opacity-70 font-semibold tracking-tight">
          {folder.name}
        </h5>

        {selected && (
          <span className="inline-block absolute top-1/4 right-1 z-10 text-creately-primary-dark dark:text-creately-neutrals-light">
            <CheckIcon className="w-6 h-6 mx-1" />
          </span>
        )}
      </div>
    </div>
  );
}
