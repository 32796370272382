import Layout from "../app.layout";
import useQueryParams from "../../hooks/useQueryParams";
import * as microsoftTeams from "@microsoft/teams-js";
import GenerateContextForm from "../../components/openai/GenerateContextForm";
import { AI_TEMPLATE_MAP } from "../../helpers/utils";

function CreateDiagramPage() {
  const query = useQueryParams();

  async function submitTaskModule(generationContext) {
    const aiTemplate = AI_TEMPLATE_MAP[generationContext.templateType];
    microsoftTeams.dialog.url.submit(
      {
        verb: "generate-context-ai-fe",
        payload: query.get("payload"),
        templateString: aiTemplate.title,
        contextScope:
          generationContext.conversationScope === "all"
            ? -1
            : generationContext.conversationLength,
        diagramType: generationContext.templateType,
      },
      process.env.REACT_APP_MANIFEST_APP_ID
    );
  }

  return (
    <Layout>
      <div className="flex items-center justify-center w-full h-screen px-4">
        <GenerateContextForm onSubmit={submitTaskModule} />
      </div>
    </Layout>
  );
}

export default CreateDiagramPage;
