function Layout({ children, bgClass = "bg-creately-neutrals-xlight" }) {
  return (
    <>
      <main className={`font-sans antialiased min-h-screen ${bgClass} sm:dark:bg-zinc-900 dark:bg-transparent`}>
        {children}
      </main>
    </>
  );
}

export default Layout;
