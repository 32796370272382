import { useParams } from "react-router-dom";
import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";
import CreatelyEditorFrameOld from "../components/CreatelyEditorFrameOld";

export default function ChannelDashboardPage() {
  const { folderId } = useParams();
  const [{ teamsContext }] = useTeams();
  
  return (
    <Layout>
      <CreatelyEditorFrameOld
        workspace="dashboard"
        allowEdit={["web", "desktop"].includes(
          teamsContext.app?.host?.clientType
        )}
        appScope="channel"
        folderId={folderId}
      />
    </Layout>
  );
}
