import { useEffect, useMemo, useState, useCallback } from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  Link,
  useLocation,
} from "react-router-dom";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import { useTeams } from "../hooks/useTeams";
import ConfigureHomePage from "./home.page";
import ConfigureWorkspacesPage from "./workspaces.page";
import Layout from "../common/app.layout";
import ConfigureFolderPage from "./folders.page";
import classnames from "classnames";
import {
  FolderIcon,
  RectangleGroupIcon,
  WindowIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../contexts/auth.context";
import UserAvatar from "../components/UserAvatar";

function ConfigurationIndexPage({ handleLogin }) {
  const [groupId, setGroupId] = useState(null);
  const { loggedIn, user } = useAuth();
  const [{ teamsContext, isInitializing }] = useTeams();
  const teamsWindowContext = useMemo(
    () => getTeamsWindowContext(teamsContext),
    [teamsContext]
  );

  const { path } = useRouteMatch();
  const location = useLocation();
  const currentTab = location.pathname.split("/")[2] || "templates";

  const addToPrivateGroup = useCallback(addUserToPrivateGroup, [
    user,
    teamsContext,
  ]);

  const addToOrgGroup = useCallback(addUserToOrgGroup, [user, teamsContext]);

  async function addUserToPrivateGroup() {
    return await fetch(
      `${process.env.REACT_APP_CREATELY_AI_SVC}/api/metadata/group/member`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.gravityToken,
        },
        body: JSON.stringify({
          channelId: teamsContext.channel.id,
          tenantId: teamsContext.user.tenant.id,
          groupName: teamsContext.channel.displayName,
          ownerGroupId: teamsContext.channel.ownerGroupId,
          uid: user.id,
          planId: user.organization.id,
        }),
      }
    )
      .then((res) => res.json())
      .then((group) => group.groupId);
  }

  async function addUserToOrgGroup() {
    return await fetch(
      `${process.env.REACT_APP_CREATELY_AI_SVC}/api/metadata/group/member`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.gravityToken,
        },
        body: JSON.stringify({
          channelId: teamsContext.team.internalId,
          tenantId: teamsContext.user.tenant.id,
          groupName: teamsContext.team.displayName,
          teamGroupId: teamsContext.team.groupId,
          uid: user.id,
          planId: user.organization.id,
        }),
      }
    )
      .then((res) => res.json())
      .then((group) => group.groupId);
  }

  useEffect(() => {
    if (isInitializing) return;
    // auto-create groups only in channel scope
    if (user && teamsContext && teamsContext.channel) {
      if (
        teamsContext.channel.membershipType === "Regular" &&
        teamsContext.team.groupId
      ) {
        // add user to existing group [created during bot 'installationUpdate']
        addToOrgGroup().then((groupId) => setGroupId(groupId));
      } else if (
        teamsContext.channel.membershipType === "Private" &&
        teamsContext.team.groupId === undefined
      ) {
        // create a private group + add user
        addToPrivateGroup().then((groupId) => setGroupId(groupId));
      }
    }
  }, [
    isInitializing,
    teamsContext,
    user,
    addToPrivateGroup,
    addToOrgGroup,
    setGroupId,
  ]);

  return (
    <Layout bgClass="bg-white">
      <div className="flex items-center mb-7 gap-3">
        <Link to="/configure">
          <div
            className={classnames({
              "overflow-hidden border dark:border-none rounded-md shadow h-full p-2 flex items-center justify-center gap-1": true,
              "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-500":
                currentTab === "templates",
              "bg-white border-gray-200 hover:border-opacity-40 hover:border-zinc-600 dark:bg-zinc-700":
                currentTab !== "templates",
            })}
          >
            <div>
              <RectangleGroupIcon className="text-creately-primary-dark dark:text-gray-100 h-6 w-7" />
            </div>
            <h2 className="text-sm font-semibold text-gray-800 dark:text-gray-100">
              New
            </h2>
          </div>
        </Link>
        <Link to="/configure/workspaces">
          <div
            className={classnames({
              "overflow-hidden border dark:border-none rounded-md shadow h-full p-2 flex items-center justify-center gap-1": true,
              "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-500":
                currentTab === "workspaces",
              "bg-white border-gray-200 hover:border-opacity-40 hover:border-zinc-600 dark:bg-zinc-700":
                currentTab !== "workspaces",
            })}
          >
            <div>
              <WindowIcon className="text-creately-primary-dark dark:text-gray-100 h-6 w-7" />
            </div>
            <h2 className="text-sm font-semibold text-gray-800 dark:text-gray-100">
              Pin a Workspace
            </h2>
          </div>
        </Link>
        {(teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.group ||
          teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.channel) && (
          <Link to="/configure/folders">
            <div
              className={classnames({
                "overflow-hidden border dark:border-none rounded-md shadow h-full p-2 flex items-center justify-center gap-1": true,
                "bg-creately-primary-xlight border-creately-primary-dark dark:bg-zinc-500":
                  currentTab === "folders",
                "bg-white border-gray-200 hover:border-opacity-40 hover:border-zinc-600 dark:bg-zinc-700":
                  currentTab !== "folders",
              })}
            >
              <div>
                <FolderIcon className="text-creately-primary-dark dark:text-gray-100 h-6 w-7"></FolderIcon>
              </div>
              <h2 className="text-sm font-semibold text-gray-800 dark:text-gray-100">
                Pin a Folder
              </h2>
            </div>
          </Link>
        )}
        <div className="ml-auto mr-4">
          {loggedIn && user && <UserAvatar user={user} />}
        </div>
      </div>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <ConfigureHomePage groupId={groupId} />}
        />
        <Route
          exact
          path={`${path}/workspaces`}
          render={() => <ConfigureWorkspacesPage groupId={groupId} />}
        />
        <Route
          exact
          path={`${path}/folders`}
          render={() => <ConfigureFolderPage groupId={groupId} />}
        />
      </Switch>
    </Layout>
  );
}

export default ConfigurationIndexPage;
