import { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  FIND_ORG_WORKSPACES,
  GET_ORG_WORKSPACES,
} from "../../queries/configure";
import Spinner from "../Spinner";
import { RadioGroup } from "@headlessui/react";
import WorkspaceCard from "../WorkspaceCard";

import SearchItem from "../SearchItem";
import { find, get, orderBy } from "lodash";
import WorkspaceSearch from "../WorkspaceSearch";
import PinnedWorkspaceCard from "../PinnedWorkspaceCard";
import { useAuth } from "../../contexts/auth.context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const UpdateContextSchema = Yup.object().shape({
  workspaceName: Yup.string(),
  workspaceId: Yup.string().required("Workspace must be selected"),
});

export default function UpdateContextForm({ onUpdate }) {
  const { user } = useAuth();
  const [pinWorkspace, setPinWorkspace] = useState(null);

  const { loading, data: orgDiagramsResponse } = useQuery(GET_ORG_WORKSPACES, {
    variables: {
      pageNumber: 1,
      perPage: 6,
      order: "dateDesc",
    },
  });

  const teamWorkspaces = useMemo(() => {
    if (!orgDiagramsResponse) return null;
    return orderBy(
      get(orgDiagramsResponse, "team.documents", []),
      ["lastUpdated"],
      "desc"
    );
  }, [orgDiagramsResponse]);

  return (
    <div className="overflow-x-auto mx-auto w-4/5 pt-8">
      {loading ? (
        <Spinner
          className="absolute top-1/2 left-1/2"
          text="Loading Workspaces"
        />
      ) : null}

      {!loading && (
        <div className="mt-2">
          <Formik
            initialValues={{
              workspaceId: "",
            }}
            validationSchema={UpdateContextSchema}
            onSubmit={(values) =>
              onUpdate({
                ...values,
                workspaceName:
                  values.workspaceName ||
                  find(teamWorkspaces, { id: values.workspaceId })?.name,
              })
            }
          >
            {({ submitForm, isValid, setFieldValue }) => (
              <div>
                <section className="my-2">
                  {pinWorkspace ? (
                    <>
                      <h3 className="font-semibold text-creately-theme-black dark:text-creately-theme-secondary text-sm py-1">
                        Following workspace will be updated
                      </h3>
                      <PinnedWorkspaceCard
                        workspace={pinWorkspace}
                        onUnpick={() => {
                          setFieldValue("workspaceId", "", false);
                          setFieldValue("workspaceName", "", true);
                          setPinWorkspace(null);
                        }}
                      />
                    </>
                  ) : (
                    <div className="flex-1 rounded-md bg-gray-50 dark:bg-zinc-800 px-3 py-1">
                      <h2 className="text-sm font-bold text-creately-primary-xdark dark:text-creately-neutrals-light">
                        Find your existing workspaces
                      </h2>
                      <WorkspaceSearch
                        gqlQuery={FIND_ORG_WORKSPACES}
                        gqlVars={
                          user ? { orgId: user?.organization?.id } : null
                        }
                        filterFn={(wksp) => !!wksp.privacy}
                      >
                        {({ workspaces: searchResults, clear }) =>
                          searchResults.map((wksp) => (
                            <SearchItem item={wksp} key={wksp.id}>
                              <button
                                onClick={() => {
                                  console.log(wksp);
                                  setFieldValue("workspaceId", wksp.id, false);
                                  setFieldValue(
                                    "workspaceName",
                                    wksp.name,
                                    true
                                  );
                                  setPinWorkspace(wksp);
                                  clear();
                                }}
                                className="text-sm font-semibold text-white bg-creately-primary-dark hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow"
                              >
                                Select Workspace
                              </button>
                            </SearchItem>
                          ))
                        }
                      </WorkspaceSearch>
                    </div>
                  )}
                </section>
                <Form>
                  <section>
                    <h2 className="text-sm font-bold text-creately-primary-xdark dark:text-creately-neutrals-light">
                      Recent Workspaces
                    </h2>
                    <Field name="workspaceId">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { setFieldValue },
                      }) =>
                        teamWorkspaces ? (
                          <RadioGroup
                            value={field.value}
                            onChange={(val) => {
                              setFieldValue("workspaceId", val, false);
                              setFieldValue(
                                "workspaceName",
                                get(find(teamWorkspaces, { id: val }), "name"),
                                true
                              );
                              setPinWorkspace(null);
                            }}
                          >
                            <div className="grid gap-4 grid-cols-1 xs:grid-cols-2">
                              {teamWorkspaces.map((doc) => (
                                <RadioGroup.Option value={doc.id} key={doc.id}>
                                  {({ checked }) => (
                                    <WorkspaceCard
                                      workspace={doc}
                                      selected={checked}
                                    />
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        ) : null
                      }
                    </Field>
                    <ErrorMessage
                      name="workspaceId"
                      component="div"
                      className="text-xs text-red-500 mt-2"
                    />
                  </section>

                  <div className="mt-3 w-full flex justify-end">
                    <button
                      type="button"
                      className="justify-center text-sm font-medium btn-primary dark:text-creately-theme-beige dark:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:bg-creately-neutrals-dark"
                      onClick={submitForm}
                    >
                      Update Workspace
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
