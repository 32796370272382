import { dateSince } from "../helpers/utils";

export default function TeamActivityCard(props) {
  const {
    activity: { author, title, timestamp },
  } = props;
  return (
    <div className="bg-white shadow-md border border-gray-200 rounded overflow-hidden px-4">
      <article className="pt-4 pb-2 font-semibold text-sm">
        <header>{title}</header>
        <div className="flex justify-between text-xs text-gray-400 no-underline pt-1">
          <div>
            <span className="font-medium">by {author.name}</span>
            <span className="font-normal text-grey">@{author.username}</span>
          </div>
          <span>{dateSince(timestamp)}</span>
        </div>
      </article>
    </div>
  );
}
