import WorkspaceThumbnail from "./WorkspaceThumbnail";

export default function RightSidebar({ workspace }) {
  return (
    <>
      <WorkspaceThumbnail workspace={workspace} />
      <div className="relative mt-4 flex items-center justify-between rounded-md mb-2">
        <section className="text-white bg-creately-primary-light dark:bg-creately-primary-xdark p-3 rounded xs:hidden">
          <h2 className="text-md">Great! 🎉</h2>
          <p className="text-xs mt-1">
            <span>
              Now that you have added a workspace to the meeting, Click on
              'Share' button below to collaborate with your team in real time.
            </span>
          </p>
        </section>
      </div>
    </>
  );
}
