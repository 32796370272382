import classNames from "classnames";
import { Suspense } from "react";
import AppInitializer from "./AppInitializer";
import { useTeams } from "./hooks/useTeams";
import Spinner from "./components/Spinner";

function App() {
  const [{ appTheme }] = useTeams();

  return (
    <div
      className={classNames({ dark: ["dark", "contrast"].includes(appTheme) })}
    >
      <Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Spinner text="Preparing Application..." />
          </div>
        }
      >
        <AppInitializer appTheme={appTheme} />
      </Suspense>
    </div>
  );
}

export default App;
