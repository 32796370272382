import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useAutoFocus from "../hooks/useAutoFocus";

const WorkspaceNameSchema = Yup.object().shape({
  workspaceName: Yup.string()
    .min(1, "Workspace Name is too short")
    .max(80, "Workspace Name is too long")
    .required("Workspace Name shouldn't be empty"),
});

export default function WorkspaceNameInput({
  onSubmit,
  placeholder,
  buttonText,
}) {
  const nameInput = useAutoFocus();

  return (
    <div>
      <Formik
        initialValues={{
          workspaceName: "",
        }}
        validationSchema={WorkspaceNameSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <Form>
            <Field name="workspaceName">
              {({ field, form: { touched, errors }, meta }) => (
                <div className="mt-6">
                  <label className="block font-bold dark:text-gray-200 text-sm mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    className="w-full text-sm py-2 px-3 focus:border-creately-primary-dark dark:bg-creately-theme-black dark:text-creately-theme-skin rounded border outline-none"
                    autoComplete="off"
                    placeholder={placeholder}
                    {...field}
                    ref={nameInput}
                  />
                  {meta.touched && meta.error && (
                    <p className="text-creately-semantics-red-light text-sm mt-2">
                      {meta.error}
                    </p>
                  )}
                </div>
              )}
            </Field>

            <div className="mt-3 w-full flex justify-end">
              <button
                type="button"
                className="justify-center text-sm font-medium btn-primary dark:text-creately-theme-beige dark:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                onClick={submitForm}
              >
                {buttonText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
