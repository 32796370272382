import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function MessageModal({
  title,
  buttonText,
  visible,
  onClose,
  children,
}) {
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-creately-theme-black dark:bg-transparent transition-colors duration-150 bg-opacity-20" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-zinc-700 shadow-xl rounded-md">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-red-600 dark:text-creately-theme-skin"
              >
                {title}
              </Dialog.Title>
              <Dialog.Description className="text-sm mt-4">{children}</Dialog.Description>
              <div className="flex items-center justify-end mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-creately-primary-dark bg-creately-primary-dark bg-opacity-10 dark:text-creately-theme-beige dark:bg-opacity-100 border border-creately-primary-dark rounded-md hover:bg-opacity-20  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={onClose}
                >
                  Continue
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
