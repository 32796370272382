import { useMutation, useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { orderBy, get } from "lodash";
import { CREATE_TEAM_WORKSPACE, GET_ALL_WORKSPACES } from "../queries/channel";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import {
  ChevronLeftIcon,
  PlusIcon,
  BoltIcon,
} from "@heroicons/react/20/solid";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";
import { getNotifications } from "../helpers/utils";
import ErrorNotification from "../components/ErrorNotification";
import { useNotifications } from "../contexts/notifications.context";
import WorkspaceCardList from "../components/WorkspaceCardList";
import { GET_TEAM_WORKSPACES } from "../queries/personal";

function ChannelWorkspacesPage() {
  const [isOpen, setIsOpen] = useState(false);
  const { folderId } = useParams();
  const history = useHistory();
  const { telemetry } = useTelemetry();
  const { show } = useNotifications();

  const { loading, data: allWorkspaceResponse } = useQuery(GET_ALL_WORKSPACES, {
    variables: { folderId },
    fetchPolicy: "no-cache",
  });

  const allWorkspaces = useMemo(() => {
    if (!allWorkspaceResponse) return null;
    const wkspSharedByOthers = get(allWorkspaceResponse, "folder.documents");
    return orderBy(wkspSharedByOthers, ["lastUpdated"], "desc");
  }, [allWorkspaceResponse]);

  const [documentCreate] = useMutation(CREATE_TEAM_WORKSPACE);

  async function createWorkspace(workspaceName) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      variables: {
        documentInput: { name: workspaceName, projectId: folderId },
      },
      refetchQueries: [GET_ALL_WORKSPACES],
      onCompleted: () => setIsOpen(false),
      onError: (error) => {
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) =>
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          )
        );
      },
    });
    return document.id;
  }

  function handleQuickStart() {
    createWorkspace("Untitled Workspace").then((workspaceId) => {
      telemetry.track("workspace.create", {
        value1Type: "Location",
        value1: "Quick Start",
      });
      history.push({
        pathname: `/app/${workspaceId}`,
        state: {
          starter: true,
          dashboardPath: `/channel/${folderId}/workspaces`,
        },
      });
    });
  }

  return (
    <>
      <Modal
        title="Create Team Workspace"
        placeholder="Your New Workspace"
        buttonText="Create Workspace"
        visible={isOpen}
        onSubmit={(formInput) => {
          createWorkspace(formInput.workspaceName);
          telemetry.track("workspace.create", {
            value1Type: "Location",
            value1: "Typical",
          });
        }}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex items-center mx-auto p-4 lg:w-4/5">
        <Link
          className="flex items-center space-x-1 text-creately-primary-dark text-sm font-bold hover:underline hover:text-green-500 duration-150 transition-colors"
          to={`/channel/${folderId}`}
        >
          <ChevronLeftIcon className="w-4 h-4" />
          Dashboard
        </Link>
      </div>

      <BrowserView>
        <div className="mx-auto px-4 lg:w-4/5 my-4">
          <h3 className="font-semibold text-creately-neutrals-xxdark dark:text-creately-neutrals-xlight dark:text-opacity-70 mb-2">
            Get Started from Scratch or find right tools for you
          </h3>
          <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 my-2">
            <button
              className="flex flex-col items-center sm:flex-row min-w-md sm:w-auto text-creately-primary-dark bg-creately-primary-xlight border shadow-md p-3 rounded hover:border-creately-primary-dark dark:bg-zinc-700 dark:border-none dark:text-white dark:hover:bg-zinc-700"
              onClick={handleQuickStart}
            >
              <BoltIcon className="w-6 h-6" />

              <div className="flex flex-col items-start px-3">
                <h4 className="text-sm font-semibold">
                  Quick Start
                </h4>
                <p className="text-gray-400 text-xs">
                  Use built-in toolkits & templates
                </p>
              </div>
            </button>
            <button
              className="flex flex-col items-center sm:flex-row min-w-md sm:w-auto text-creately-primary-dark bg-white border shadow-md p-3 rounded hover:border-creately-primary-dark dark:bg-zinc-800 dark:border-none dark:text-white dark:hover:bg-zinc-700"
              onClick={() => setIsOpen(true)}
            >
              <PlusIcon className="w-6 h-6" />

              <div className="flex flex-col items-start px-3">
                <h4 className="text-sm font-semibold">
                  Create a Workspace
                </h4>
                <p className="text-gray-400 text-xs">Start from scratch</p>
              </div>
            </button>
          </div>
        </div>
      </BrowserView>
      <div className="mx-auto px-4 lg:w-4/5">
        <h3 className="font-semibold text-creately-neutrals-xxdark dark:text-creately-neutrals-xlight dark:text-opacity-70 mb-4">
          Your Team&lsquo;s Workspaces
        </h3>
        {loading && <Spinner text="Loading All Team Workspaces" />}
        {allWorkspaces && (
          <WorkspaceCardList
            workspaces={allWorkspaces}
            dashboardPath={`/channel/${folderId}/workspaces`}
            contextQuery={[GET_TEAM_WORKSPACES]}
            placeholderText={[
              "Creately makes your meetings and channels better.", 
              "Have key resources and wiki pinned inside Creately. Easier to understand and edit.",
              "Add Creately to your next meeting and let everyone join in."
            ]}
          />
        )}
      </div>
    </>
  );
}

export default ChannelWorkspacesPage;
