import React from "react";
import { dateSince } from "../helpers/utils";
import DocumentStatusTag from "./DocumentStatusTag";

export default function SearchItem({ item, children }) {
  return (
    <div className="group flex items-center justify-between bg-gray-50 hover:bg-gray-100 border border-gray-100 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:border-none dark:shadow-md transition-colors rounded-md py-1 px-2">
      <div>
        <div className="flex items-center space-x-2">
          <h5 className="text-sm text-gray-900 dark:text-creately-neutrals-xlight dark:text-opacity-70 font-semibold tracking-tight">
            {item.name}
          </h5>
          <DocumentStatusTag status={item.status} />
        </div>
        <h6 className="text-xs font-medium py-1 text-gray-400 dark:text-opacity-50">
          {dateSince(item.lastUpdated)}
        </h6>
      </div>
      <div className="self-start md:group-hover:block">{children}</div>
    </div>
  );
}
